import React from 'react'

export const PoliticaEnvio = () => {
  return (
    <div className='politica'>
        <p className='titulo'>POLÍTICA DE ENVÍO</p>
        <p className='texto'>Es importante que revice la validación de cobertura antes de realizar el pago, con el fin de poder conocer si su lugar de residencia se encuentra dentro de las zonas de cobertura de nuestros pedidos.</p>
        <p className='texto'>El Usuario acepta completamente la política de envíos, disponible en nuestra tienda online www.comprandoenbello.com, declara conocerla y estar de acuerdo con la misma. Esta información podrá modificarse, ampliarse o complementarse, en ese caso el Usuario podrá verificar los cambios y actualizaciones revisando nuestra tienda online.</p>
        <p className='texto'>Los productos adquiridos a través del comprandoenbello.com, se sujetarán a las condiciones de despacho y entrega disponibles por las tiendas. La información del lugar de envío es de exclusiva responsabilidad del Usuario. El plazo de despacho y entrega se especifica en cada caso al momento de finalizar la compra. Los plazos de días hábiles excluyen los fines de semana (domingos y festivos). El vendedor podrá entregar la información de despacho otorgada por el usuario a terceros con el objetivo de llevar a cabo la distribución de los productos.</p>
        <p className='titulo2'>Cobertura</p>
        <p className='texto'>comprandoenbello.com, realiza despachos de productos inicialmente dentro del Municipio de Bello a través de transportadores independientes y manejada por cada almacén, que garantiza la seguridad y cobertura, para que la compra del Usuario llegue a la dirección que desea.</p>
        <p className='titulo2'>Tiempos de despacho y entrega</p>
        <p className='texto'>El tiempo de despacho de los productos es máximo de 3 días hábiles, asegurando que el producto llegue al usuario en el tiempo máximo previsto en los términos y condiciones ofrecidos por el Vendedor de acuerdo con su naturaleza comercial.</p>
        <p className='texto'>Las entregas no se pueden realizar en un horario exacto. Los datos del lugar de envío del pedido serán responsabilidad exclusiva del usuario. De manera previa al proceso de pago.</p>
        <p className='texto'>En caso de que el producto tenga señas de daños o rupturas en su empaque en el momento de la entrega, no deberá recibir el producto o en su defecto debes registrarlo a través de nuestro correo comprandoenbello@gmail.com. Remitirse a Políticas de Cambios y Devoluciones – Derecho de retracto.</p>
        <p className='titulo2'>Costo del envío</p>
        <p className='texto'>El costo de envío de los pedidos será determinado por las políticas del vendedor en el momento de la liquidación de la orden, antes de realizar el pago.</p>
    </div>
  )
}
