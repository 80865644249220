import React from 'react'
import './politicas.css'

const PoliticaReenbolso = () => {
  return (
    <div className='politica'>
        <p className='titulo'>Política de reembolso</p>    
        <p className='texto'>El cliente podrá solicitar una anulación o cancelación de una orden de compra  realizada a través comprandoenbello.com. Solamente es posible hacerlo el mismo día de la compra comunicándose vía correo electrónico en un horario de 8:00 a.m. a 5:00 p.m. de lunes a viernes. Las ventas efectuadas fuera de dichos horarios o que ya posean un estado de despachado, no podrán ser anuladas o canceladas.
        </p>
        <p className='texto'>La reversión del pago se podrá solicitar si:</p>
        <ul>
            <li>Fue objeto de Fraude.</li>
            <li>Fue una operación no solicitada.</li>
            <li>El producto adquirido no se ha recibido.</li>
            <li>El producto entregado no corresponda a lo solicitado, no cumpla con las características inherentes o las atribuidas por la información que se suministre sobre él.</li>
            <li>El producto se encuentre defectuoso.</li>
        </ul>
        <p className='texto'>Para los reembolsos comprandoenbello.com, contará con 30 días calendario a partir del momento en que se reciban los documentos necesarios que apliquen para cada caso y éstos sean aprobados. Si el usuario se encuentra dentro de uno de los casos descritos aneriormente, podrá hacer la solicitud por medio de los canales suministrados por comprandoenbello.com, el tiempo para presentar esta solicitud será de cinco (5) días hábiles siguientes a la fecha en la cual tuvo conocimiento de cualquiera de las casuísticas determinadas por la ley, y deberá ser solicitado por el titular de la compra, Dentro de este mismo tiempo el usuario deberá poner a disposición del Vendedor el producto o productos del pedido correspondiente a la reversión solicitada, el proceso de devolución se encontrará sujeto a las políticas de cambios y devoluciones del Vendedor.</p>
        <p className='texto'>Para estos casos el usuario debe enviar a comprandoenbello@gmail.com , la solicitud de la reversión con la siguiente información:</p>
        <ul>
            <li>Número de orden de compra, producto y valor por el que se solicita la reversión.</li>
            <li>Adjuntar copia de la transacción (voucher o comprobante del pago con número, fecha y hora).</li>
            <li>Adjuntar copia del documento de identidad del propietario.</li>
        </ul>
        <p className='texto'>En caso de que el pago a reembolsar corresponda a varios productos incluidos en un mismo pedido, el usuario podrá solicitar la reversión parcial del pago de aquellos respecto de los cuales realiza la solicitud.</p>
        <p className='texto'>Los reembolsos de dinero por procesos de retracto, garantías o cancelación de orden se realizarán a través del mismo método de pago utilizado inicialmente. comprandoenbello.com sólo realizará el reembolso del dinero al titular de la compra, una vez el reembolso sea aprobado.</p>
        <p className='texto'>En el evento en que se demuestre dentro del proceso suscitado por controversias en la solicitud y trámite de la reversión del pago que el consumidor obró de mala fe, por ejemplo, que no existió fraude o que el bien no presenta defecto alguno, el Decreto 587 del 2016 señala que la Superintendencia de Industria y Comercio podrá imponerle sanciones pecuniarias hasta por 50 salarios mínimos mensuales legales vigentes.</p>
    </div>
  )
}

export default PoliticaReenbolso