import React, {useContext,useEffect, useRef, useState} from 'react'
import { GlobalState } from '../../../../GlobalState';
import { Link } from "react-router-dom";    
import './promoregresiva.css'
import interrogacion from './icon/Interrogacion.jpg';
import '../slideItem2/slideItem2.css';



const PromoRegresiva = () => {
    const state = useContext(GlobalState);
    const [timerDay, setTimeDay] = useState('00');
    const [timerHours, setTimerHours] = useState('00');
    const [timerMinutes, setTimerMinutes] = useState('00');
    const [timerSeconds, setTimerSeconds] = useState('00');
    const [superpromo, setSuperPromo] = state.productsAPI.superpromo;
    const addCart = state.userApi.addCart;
    const [fechaPromo, setFechaPromo] = state.productsAPI.fechaPromo;
    
    let interval = useRef();
    let countdownDate = '';


    const adicionarproducto = () =>{
        const now = new Date().getTime();
        const tiempo = countdownDate - now;
        if(tiempo > 0){
            addCart(superpromo[0])
        }else{
            alert('El tiempo de la promoción ya termino.')
        }
      };
      const verproducto = (id) =>{
        const now = new Date().getTime();
        const tiempo = countdownDate - now;
        // if(tiempo > 0){     
            <Link to={`/detail/${superpromo[0]._id}`}></Link>
        // }
      };
    const starTimer = () =>  {    
        if(superpromo.length > 0){
            if(fechaPromo.length > 0){
                countdownDate = new Date(fechaPromo[0].valor).getTime();
            }       
        }        

        interval = setInterval(() => {
            const now = new Date().getTime();
            const distance = countdownDate - now;

            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));  
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            if(distance < 0 ) {
                clearInterval(interval.current);
            }else {
                setTimeDay(days);
                setTimerHours(hours);
                setTimerMinutes(minutes);
                setTimerSeconds(seconds);
            }
        },1000)
    };

    useEffect(() => {
        starTimer();
        return () => {
            clearInterval(interval.current);
        };
    });

  return (
    <div className="flex-container">
    <div className='cart'>  
        {
            superpromo.length > 0 ?
            <h4>{superpromo[0].super_promo + '%'}</h4>
            :''
        }
        {
            superpromo.length > 0 ? <img src={superpromo[0].images.url}   alt='' />
            : <img src={interrogacion} alt='' />
        }            
        <div className='box'>
            {
                superpromo.length > 0 ? 
                <h2>{superpromo[0].title}</h2>   
                : ''
            }
            {
                superpromo.length > 0 ? 
                <h1>{superpromo[0].store}</h1>    
                : ''
            }
            {
                superpromo.length > 0 ? 
                    <div className="precio">
                        <span>${new Intl.NumberFormat().format(superpromo[0].price-((superpromo[0].price * superpromo[0].super_promo)/100))}</span> 
                        <span className="preci2">
                        <span className="antes">Antes/</span>${new Intl.NumberFormat().format(superpromo[0].price)}</span>
                    </div>  
                    : ''
            } 
            {
                superpromo.length > 0 ? 
                <p>{superpromo[0].description}</p>
                : ''
            }                    
        </div> 
    </div>
    <div className="conteo">
        <div className="titulo">
            {
                superpromo.length > 0 ? 
                <h1>{superpromo[0].store}</h1>    
                : ''
            }
        </div>
        <div className="titulo">
            <h1>
                SOLO POR POCOS DÍAS
            </h1>
        </div>
        <div className="sub_titulo">
            <h3>
                No te quedes sin comprarlo
            </h3>
        </div>
    <div className='reloj'>          
        <section>
            {
                superpromo.length > 0 ?
                    <p className='tiempo'>{timerDay}</p>
                    :
                    <p className='tiempo'>00</p>
            }
        <p><small>DIAS</small></p>
        </section>
        <span>:</span>
        <section>
            {
                superpromo.length > 0 ?
                <p className='tiempo'>{timerHours}</p>
                    :
                    <p className='tiempo'>00</p>
            }           
            <p><small>HORAS</small></p>
        </section>
        <span>:</span>
        <section>
            {
                superpromo.length > 0 ?
                    <p className='tiempo'>{timerMinutes}</p>
                    :
                    <p className='tiempo'>00</p>
            }          
            <p><small>MINUTOS</small></p>
        </section>
        <span>:</span>
        <section>
            {
                superpromo.length > 0 ?
                    <p className='tiempo'>{timerSeconds}</p>
                    :
                    <p className='tiempo'>00</p>
            }
            <p><small>SEGUNDOS</small></p>
        </section>
    </div>
    <div className='row_btn_promo'>
        {
            superpromo.length === 0 ? 
                <Link className='btn_buy_promo' to='#!' style={{pointerEvents: 'none'}}>    
                    Comprar
                </Link>
                : 
                <Link className='btn_buy_promo' to='#!'  onClick={() => adicionarproducto()}>
                    {/* <Link className='btn_buy_promo' to='#!'  onClick={() => addCart(superpromo[0])}> */}
                    Comprar
                </Link>
        }
        {
            superpromo.length === 0 ?
            <Link className='btn_view_promo' to='#!' style={{pointerEvents: 'none'}} >
                Ver
            </Link>
            :   
            <Link className='btn_view_promo' to={`/detail/${superpromo[0]._id}`}>
                Ver
            </Link>
        }
    </div>
  </div>
  </div>
  )
}

export default PromoRegresiva
