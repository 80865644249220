import React, {useContext} from 'react';
import {GlobalState} from "../../../GlobalState";

const FilterPromo = () => {
    const state = useContext(GlobalState);
    const [categories] = state.categoriesAPI.categories;
    const [category, setCategory] = state.promocionesAPI.category;
    // const [page, setPage] = state.productsAPI.page;


const handleCategory = e => {
    setCategory(e.target.value);
    // setPage(1);
}

const scrollup = () => {
    window.scrollTo({
      top: 140,
      behavior: 'smooth'
    })
  }

  return (
    <div className="filter_promo">
       <div className="row promo">
            <span>Buscar por Categoría : </span>
            <select name="category" value={category} onChange={handleCategory} onClick={scrollup}>
                <option value="">Todos Los Productos</option>
                {
                    categories.map(category => (
                        <option value={'category=' + category._id} key={category._id}>
                            {category.name}
                        </option>
                    ))
                }
            </select>
       </div> 
    </div>
  )
}

export default FilterPromo