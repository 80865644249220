import React from 'react'
// import {Link} from 'react-router-dom';
import BtnRender from './BtnRender';

// function ProductItem({product,setProducts,cart,isAdmin,token,callback,setCallback}) {
function ProductItem({product,isAdmin, deleteProduct, handleCheck}) {

  return (
    <div>
          {
            <div className='product_cart'>
              {
                isAdmin && <input type='checkbox' checked={product.checked}
                onChange={() => handleCheck(product._id)} />
              }
              {
                !isAdmin ?
                product.promotion === true ? <h3>{product.dto + '%'}</h3>: null
                :null
              }
              
              <img src={product.images.url} alt='' />
              <div className='product_box'>
                  <h2 title={product.title}>{product.title}</h2>
                  <h1 store={product.store}>{product.store}</h1>                 
                  {
                    product.promotion === true ?
                      product.dto > 0 ? 
                      <div className="precio">
                        <span>${new Intl.NumberFormat().format(product.price-(product.price * product.dto)/100)}</span>
                        <span className="preci2"><span className="antes">Antes-</span>${new Intl.NumberFormat().format(product.price)}</span>
                      </div>                      
                      :<span>${new Intl.NumberFormat().format(product.price)}</span>
                    :            
                      <span>${new Intl.NumberFormat().format(product.price)}</span>
                  }                 
                  <p>{product.description}</p>
              </div>     

              <BtnRender product={product} deleteProduct={deleteProduct}/>      
            </div> 
           }
    </div>
  )
}

export default ProductItem