import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import emailjs from '@emailjs/browser';
import {GlobalState} from '../../../../GlobalState';
import {useNavigate, useParams} from 'react-router-dom';
// import Loading from '../loading/Loading';
import { ReactComponent as Loader } from './loader.svg'
// import nodemailer from "nodemailer";


import '../mailer/mailer.css';

const Mailer = () => {
  const state = useContext(GlobalState);
  const [cart, setCart] = state.userApi.cart;
  const [token] = state.token;
  const [callback, setCallback] = state.productsAPI.callback;
  const [total, setTotal] = useState(0);
  const payment = useNavigate();
  const [user, setUser] = useState(
    {
      user_name:state.userApi.user[0].name, 
      user_email:state.userApi.user[0].email, 
      user_address:'',
      user_message:'',
      user_telephone:''
    }
  );

  // const [isloading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const getTotal = () => {
      const total = cart.reduce((prev, item) => {
        // return prev + (item.price * item.quantity);
        if(item.super_promo > 0){
          return prev + ((item.price - ((item.price * item.super_promo)/100)) * item.quantity);
        }else{
          if(item.promotion === true){
            return prev + ((item.price -((item.price * item.dto)/100)) * item.quantity);
          }else{
            if(item.price2 > 0){
              return prev + (item.price2 * item.quantity);
            }else {
              return prev + (item.price * item.quantity);
            }
          }  
        } 
      },0)
      setTotal(total);
    } 
    getTotal();
  },[]);

  const onChangeInput = e => {
    const {name,value} = e.target;
    setUser({...user,[name]:value});
    }


  const addToCart = async (cart) => {
    await axios.patch('/user/addcart',{cart}, {
      headers: {Authorization: token}
    });
  };

  const sendEmail = (event) => {
    event.preventDefault();

    setDisabled(true);
    
    // emailjs.sendForm('service_v1e6wbe','template_rr57svp',event.target,'UvRIe2qNMbORlL37j')
    // .then(response => {
    //   if(response.status === 200) {
        

        PedidoPorTienda();
        
        alert('Su orden de compra fue realizada con éxito.');

       
        setDisabled(false);
  //     } else {
  //         alert('El Mensaje no pudo enviarse.')};     
  //   })
  //   .catch(error => console.log(error))
  }

  const PedidoPorTienda = async() => {

    const paymentID = '0';
    // const idconsecutivoventa = idVentaTienda[0]._id;

    const address = {
      recipient_name: user.user_name,
      line1: user.user_address,
      postal_code: '05',
      country_code: "COP",
      message: user.user_message,
      telephone:user.user_telephone
    }

    await axios.post('/api/payment', {cart,paymentID, address}, {
      headers: {Authorization: token}
    });

    setCart([]);
    addToCart([]);
    setCallback(!callback);
    payment('/');
  };

  const color_fondo = {
    background: "white",
    color: 'black',
    cursor: 'default',
    color: 'gainsboro'
  }

  return (  
    <div className="form-mail">                                           
      <div className='mail'>         
        <form onSubmit={sendEmail}>                        
            <div className="Inicio">
              <h3>Contactar Tienda</h3>          
              <h3>Total Compra: ${new Intl.NumberFormat().format(total)}</h3>
            </div>
            <input type="text" name='user_name' required 
            placeholder='Name' value={user.user_name} onChange={onChangeInput} />
            <input type="email" name='user_email' required 
            placeholder='Email' value={user.user_email} onChange={onChangeInput} />
            <input type="text" name='user_address' required 
            placeholder='Dirección' value={user.user_address} onChange={onChangeInput} />
            <input type="text" name='user_telephone' required 
            placeholder='Teléfono' value={user.user_telephone} onChange={onChangeInput} />
            <textarea name='user_message' required 
            placeholder='Mensaje' value={user.user_message} onChange={onChangeInput}></textarea>
            <div className="row">
              {/* <button type='submit' disabled={disabled}>Comprar</button>  */}
              <button type='submit' >{!disabled ? 'Comprar' 
              : <Loader className="spinner" />}</button>                         
            </div>
        </form>
        <div> 
        </div>
      </div>
    </div>
  )
}



export default Mailer