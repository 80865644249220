import React, { useContext, useEffect, useState } from 'react'
import {GlobalState} from '../../../GlobalState';
import {Link} from 'react-router-dom';
import axios from 'axios';
import Loading from '../Utils/loading/Loading';


const OrderHistory = () => {
    const state = useContext(GlobalState);
    const [history, setHistory] = state.userApi.history;
    const [isAdmin] = state.userApi.isAdmin;
    const [isStore] = state.userApi.isStore;
    const [user] = state.userApi.user;
    const [token] = state.token;
    const [produtstore, setProductStore] = useState(false);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if(token) {
            const getHistory = async () => {
                if(isAdmin || isStore){
                    const res = await axios.get('/api/payment', {
                        headers: {Authorization: token}
                    });
                    setHistory(res.data);                    
                }else{
                    const res = await axios.get('/user/history', {
                        headers: {Authorization: token}
                    });
                    setHistory(res.data);
                }
            }
            getHistory();
        }
    },[token,isAdmin,setHistory,isStore])


    const actualizar = (id,status) =>{
        history.forEach(item => {
          if(item._id === id) {
            item.status = status;
          }
        });
        setHistory([...history]);
      };

    const editPayment = async (id,status) => {
        try {
            const res = await axios.put(`/api/payment/${id}`, {status: status}, {
                headers: {Authorization: token}
            })
            actualizar(id,status)
            alert(res.data.msg)
        } catch (err) {
            alert(err.response.data.msg)
        }      
    }

if(loading) return <div><Loading/></div>

  return (
    <div className="history-pages">
        <h2>Historial de compras</h2>
        {
            isStore ?
                <h4>Tienes {history.filter(product2 => product2.cart.filter(pro => pro.store === user.name).length).length} órdenes</h4>                     
            :
                <h4>Tienes {history.length} órdenes</h4>
        }  
        {history.length === 0 && <Loading />}            
        <div id='listHistory'>
            <table>
                <thead>
                    <tr>
                        <th>ID Pago</th>
                        <th>Fecha de Compra</th>
                        <th>Estado Entrega</th>
                        {
                            isAdmin || isStore ? <th></th> : null
                        }                      
                        <th>Detalle</th>     
                    </tr>
                </thead>
                <tbody>
                    {                       
                        isStore ?
                            history.map(ite => ( 
                                <tr key={ite._id}>
                                {                                               
                                    ite.cart.filter(product2 => product2.store === user.name).length > 0 ?
                                    <>
                                        {
                                        ite.paymentID.substr(0,5) === 'PAYID' ? 
                                            <td>{ite.paymentID}</td>
                                        :
                                            <td>Factura Nro. {ite.paymentID}</td>
                                        }                         
                                        <td>{new Date(ite.createdAt).toLocaleDateString()}</td>
                                        {
                                            ite.status ? 
                                            <>  
                                            <td className='estado2'>Entregado</td> 
                                            <td className='boton1'><button onClick={() => editPayment(ite._id, false)}>Reversar</button></td>  
                                            </>
                                            : 
                                            <>
                                            <td className='estado1' >Pendiente</td>
                                            <td className='boton2'><button onClick={() => editPayment(ite._id, true)}>Actualizar</button></td> 
                                            </>
                                        }                            
                                        <td><Link to={`/history/${ite._id}`}>Ver</Link></td>
                                    </>
                                    :null
                                }
                                </tr>                             
                            ))
                        :
                        history.map(items => ( 
                             <tr key={items._id}> 
                                {
                                items.paymentID.substr(0,5) === 'PAYID' ? 
                                    <td>{items.paymentID}</td>
                                :
                                    <td>Factura Nro. {items.paymentID}</td>
                                }                         
                                <td>{new Date(items.createdAt).toLocaleDateString()}</td>
                                {
                                    items.status ? 
                                        <td className='estado2' >Entregado</td>                            
                                    : 
                                        <td className='estado1' >Pendiente</td>                        
                                }
                                {
                                    isAdmin? 
                                    items.status ? 
                                    <td className='boton1'><button onClick={() => editPayment(items._id, false)}>Reversar</button></td>                      
                                    : 
                                    <td className='boton2'><button onClick={() => editPayment(items._id, true)}>Actualizar</button></td>  
                                    : null
                                }                             
                                <td><Link to={`/history/${items._id}`}>Ver</Link></td>
                              </tr>                           
                        ))
                    }
                </tbody>
            </table>
        </div>
    </div>
  )
}

export default OrderHistory