import React from 'react'
import './loading.css'

const Loading = () => {
  return (
    <div className='load-page'>
        <div className='loader'>
            <div>
                <div>
                    <div>
                        <div>
                            <div>
                                <div>
                                    <div>
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                         </div>
                     </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Loading