import React, {useContext,useState} from 'react';
import { GlobalState } from '../../../GlobalState';
import PromoItem from '../../mainpages/Utils/promoitem/PromoItem';
import Publicidad from '../Utils/publicidad/Publicidad';
import axios from 'axios';
import Loading from '../Utils/loading/Loading';
import BackToTopButton from '../Utils/BackToTopButton/BackToTopButton';
import { FaAngleDoubleRight } from 'react-icons/fa';
import { FaAngleDoubleLeft } from 'react-icons/fa';
import { Link } from "react-router-dom";
import FilterPromo from '../../mainpages/ofertas/FilterPromo';


const Ofertas = () => {
    const state = useContext(GlobalState);
    const [promociones, setPromociones] = state.promocionesAPI.promociones;
    const [loading, setLoading] = useState(false);
    const [isAdmin] = state.userApi.isAdmin;
    const [token] = state.token;
    const [callback, setCallback] = state.productsAPI.callback;
    const [callback2, setCallback2] = state.promocionesAPI.callback;
    const [pagepromo, setPagepromo] = state.promocionesAPI.pagepromo;
    const [isStore] = state.userApi.isStore;
    const [postPerPage] = useState(12);
    const [user] = state.userApi.user;
    const lastPost = pagepromo * postPerPage;
    const firstPost = lastPost - postPerPage;
    const currentPost = promociones.slice(firstPost, lastPost);
    const pageNumber = [];

    for (let i = 1; i <= Math.ceil(promociones.length / postPerPage); i++) {
      pageNumber.push(i);
    }

    // const ChangePage = (pageNumber) => {
    //   setPagepromo(pageNumber);
    // };

    const handleCheck = (id) => {
        promociones.forEach(product => {
          if(product._id === id) product.checked = !product.checked
        });
        setPromociones([...promociones]);
      }

      const deleteProduct = async(id, public_id) => {
        try {
          setLoading(true)
          // console.log(id)
          const destroyImg = await axios.post('/api/destroy', {public_id}, {
            headers: {Authorization: token}
          })
    
          const deleteProduct = await axios.delete(`api/products/${id}`, {
            headers: {Authorization: token}
          })
    
          await destroyImg
          await deleteProduct
          setCallback(!callback)
          setCallback2(!callback2)
          setLoading(false);
        } catch (err) {
          alert(err.response.data.msg)
        }
      }

      const color_fondo = {
        background: "white",
        color: 'gainsboro',
        cursor: 'default',
      }

      if(loading) return <div><Loading/></div>

  return (
    <div className='general'>   
        <>
          <Publicidad tipo={7} stilo={1}/>
        </>
        <div className="filtrofijo">
          <FilterPromo/>
        </div>
        <div className="ofertas">
            {            
              isStore ? 
              promociones.map(product => {
                if(product.store === user.name)
                return  <PromoItem key={product._id} product={product} 
                isAdmin={isAdmin} deleteProduct={deleteProduct} handleCheck={handleCheck}/> 
            }) 
              :
                  promociones.map(product => {
                      return  <PromoItem key={product._id} product={product} 
                      isAdmin={isAdmin} deleteProduct={deleteProduct} handleCheck={handleCheck}/> 
                  }) 
            } 
        </div>   
        {promociones.length === 0 && <Loading />}
        <div className='load_more'>
          {pagepromo === 1 ? <FaAngleDoubleLeft className='next' style={color_fondo}  disabled={true}>
            anterior             
          </FaAngleDoubleLeft>
          :
          <FaAngleDoubleLeft className='next' onClick={() => setPagepromo(pagepromo - 1)}>
                anterior             
          </FaAngleDoubleLeft>}
                
          {pagepromo * postPerPage >= promociones.length ? <FaAngleDoubleRight className='next' 
            style={color_fondo} disabled={true}>
            Siguiente             
          </FaAngleDoubleRight>
          :<FaAngleDoubleRight className='next' onClick={() => setPagepromo(pagepromo + 1)}>
                Siguiente             
            </FaAngleDoubleRight>}
        </div>
        <>
          <div className='publi'>
            <Publicidad tipo={8} stilo={1}/>
          </div>
        </>
        <div className="div">
          <BackToTopButton top={140}/>
        </div>
    </div>
  )
}

export default Ofertas