import React from 'react'

export const ComoComprar = () => {
  return (
    <div className='politica'>
        <p className='titulo'>¿CÓMO PUEDO COMPRAR?</p>
        <p className='titulo2'>Comprar en www.comprandoenbello.com es muy fácil, solo debes seguir los siguientes pasos:</p>
        <ul>
            <li>Ingresa a www.comprandoenbello.com.</li>
            <li>Regístrate o ingresa en nuestra página (en la opción ubicada en la parte superior derecha).</li>
            <li>Busca el producto que deseas, agrégalo al carrito de compras.</li>
            <li>Debes dar click en comprar o ver.</li>
            <li>Debes dar click en añadir al carrito</li>
            <li>Selecciona el medio de pago a usar.</li>
            <li>Ingresa los datos de la dirección de entrega.</li>
            <li>Dar click en comprar.</li>
        </ul>
        <p className='texto'>NOTA: Al finalizar el proceso de compra, debe llegar un correo de confirmación de pedido al correo registrado.</p>

    </div>
  )
}
