import React, {useContext} from 'react';
import { GlobalState } from '../../GlobalState';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import SlideItem from '../mainpages/Utils/slideItem/SlideItem';



const Slideshow = ({slider}) => {

  const state = useContext(GlobalState);


  const [sliders] = state.sliderAPI.sliders;

    return (
      <div>
        <div className='slideshow'>
          {/* <div className='textoinicial1'>
            <p>Entérate</p>
          </div> */}
            <Slide easing="ease">
              {                   
                  sliders.map(slide => { 
                    return slide.public === slider ?
                      <SlideItem key={slide._id} slide={slide}/> : null
                  })
              }
            </Slide>
        </div>
      </div>
    )
};

export default Slideshow;