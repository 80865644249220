import React,{useContext} from 'react';
import { Routes ,Route, BrowserRouter} from 'react-router-dom'
import Products from './products/Products';
import DetailProduct from './detaliProduct/DetailProduct'
import Login from './auth/Login';
import Register from './auth/Register';
import OrderHistory from './history/OrderHistory';
import OrderDetail from './history/OrderDetail';
import Cart from './cart/Cart';
import NotFound from "./Utils/not_found/NotFound";
import Mailer from './Utils/mailer/Mailer';
import Categories from './categories/Categories';
import CreateSlider from './Utils/publicidad/CreatePublic'
import CreateProduct from './createProducts/CreateProduct';
import PoliticaReenbolso from './Politicas/PoliticaReenbolso';
import { PoliticaGarantia } from './Politicas/PoliticaGarantia';
import { PoliticaEnvio } from './Politicas/PoliticaEnvio';
import { ComoComprar } from './Politicas/ComoComprar';
import Ofertas from './ofertas/Ofertas';
import ParametrosGenerales from './parametrosGenerales/ParametrosGenerales';
import Home from './home/Home';
import Directorio from './directorio/Directorio';
import Premios from './premios/Premios';

import {GlobalState} from '../../GlobalState';
import ScrollToTop from './Utils/ScrollToTop';

import ForgotPassword from './auth/ForgotPassword';
import PasswordReset from './auth/PasswordReset';

// import { Element } from 'react-scroll';


function Pages() {
  const state = useContext(GlobalState);
  const [isLogged] = state.userApi.isLoggued;
  const [isAdmin] = state.userApi.isAdmin;
  const [isStore] = state.userApi.isStore;

  return (    
    <>
    <ScrollToTop />
      <Routes>
        <Route path='/' element={<Products />} />>
        {/* <Route path='/promociones' element={<Promociones />} />> */}
        <Route path='/detail/:id' element={<DetailProduct />} />>
        <Route path='/login' element={isLogged ? <NotFound /> :  <Login />}/>>
        <Route path='/register' element={isLogged ? <NotFound /> : <Register />} />>

        <Route path='/category' element={isAdmin ? <Categories /> : <NotFound />} />>
        <Route path='/create_product' element={isAdmin || isStore ? <CreateProduct /> : <NotFound />} />>
        <Route path='/edit_product/:id' element={isAdmin || isStore ? <CreateProduct /> : <NotFound />} />>

        <Route path='/history' element={isLogged ? <OrderHistory /> : <NotFound />} />>
        <Route path='/history/:id' element={isLogged ? <OrderDetail /> : <NotFound />} />>

        <Route path='/cart' element={<Cart />} />>
        <Route path='/contacto' element={<Mailer />} />>
        <Route path='/ofertas' element={<Ofertas />} />>

        <Route path='/Create_slider' element={isAdmin ? <CreateSlider /> : <NotFound />} />>
        <Route path='/Create_parametros' element={isAdmin ? <ParametrosGenerales/> :<NotFound />} />>

        <Route path='/forgotPassword' element={<ForgotPassword />} />>
        <Route path='/PasswordReset/:id/:token/:otp/:email' element={<PasswordReset />} />>

        <Route path='/reembolso' element={<PoliticaReenbolso />} />>
        <Route path='/garantia' element={<PoliticaGarantia />} />>
        <Route path='/envio' element={<PoliticaEnvio />} />>
        <Route path='/comocomprar' element={<ComoComprar />} />>

        <Route path='/home' element={<Home />} />>
        <Route path='/Directorio' element={<Directorio />} />>
        <Route path='/Premios' element={<Premios />} />>

        <Route path='*' element={<NotFound />} />>
      </Routes>
      </>
  )
};

export default Pages