import React from 'react'

import "../premios/premios.css"

import '../parametrosGenerales/parametrosGenerales.css';

const Premios = () => {
  return (
    <div className='premios'>Premios</div>
  )
}

export default Premios