import React, {useState, useContext} from 'react'
import { GlobalState } from '../../../../GlobalState';
import axios from 'axios';
import Loading from '../loading/Loading';

const CreatePublic = () => {
    const state = useContext(GlobalState);
    const [sliders] = state.sliderAPI.sliders;

    const [slid, setSlid] = useState('');
    const [token] = state.token;
    const [callback, setCallback] = state.sliderAPI.callback;
    const [onEdit, setOnedit] = useState(false);
    const [id,setID] = useState('');
    const [publicid,setPublicid] = useState('');
    const [images, setImages] = useState(false);
    const [isAdmin] = state.userApi.isAdmin;
    const [loading, setLoading] = useState(false);

    const imagen = {
        public_id: '',
        url: ''
    };
    const styleUpload = {
        display: images ? 'block' : 'none'
    };

    const createSlider = async e => {
        e.preventDefault();
        try {
            if(onEdit){
                if(!images) return alert('No se ha cargado la imagen');
                console.log(publicid)
                const res = await axios.put(`/api/slider/${id}`, {name: slid,images: images,public: publicid}, {
                    headers: {Authorization: token}
                });
                alert(res.data.msg);
            }else {
                
                const res = await axios.post('/api/slider', {name: slid, images: images,public: publicid}, {
                    headers: {Authorization: token}
                });
                alert(res.data.msg);
            }
            setOnedit(false);
            setSlid('');
            setPublicid('');
            setImages(false);
            setCallback(!callback);
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const editCategoria = async (id, name,images,public_id) => {
        setID(id);
        setSlid(name);
        setImages(images);
        setPublicid(public_id);
        setOnedit(true);
    }
    
    
    const deleteCategoria = async id => {
        try {
            const res = await axios.delete(`/api/slider/${id}`,{
                headers: {Authorization: token} 
            });
            alert(res.data.msg);
            setCallback(!callback);
            setOnedit(false);
        } catch (err) {
            alert(err.response.data.msg);
        }
    } 
    
    const handleUpload = async e => {
        e.preventDefault();
        try {
            console.log('hola')
            if(!isAdmin) return alert('Se requiere ser Administrador.');
            const file = e.target.files[0];
            
            if(!file) return alert('El archivo no existe.');
    
            if(file > 1024 * 1024) 
                alert('Tamaño de archivo muy grande.');
    
            if(file.type !== 'image/jpeg' && file.type !== 'image/jpg') 
                return alert('El formato de archivo es incorrecto.')
    
            let formData = new FormData();
            formData.append('file', file);
            formData.append('upload_preset', 'c9mchxoy')
    
            const cloud_name = '';

            setLoading(true)
            // const res = await axios.post('/api/upload', formData, {
            //     headers: {'content-type': 'multipart/form-data', Authorization: token}
            // })
            const res2 = await axios.post('/api/upload',cloud_name, {
                headers: {Authorization: token}})

            const res = await axios.post(`https://api.cloudinary.com/v1_1/${res2.data}/image/upload`, formData);  

            const imagen = {
                public_id: res.data.public_id,
                url: res.data.secure_url
                };
                
        //  const aaaa = JSON.stringify(imagen)
        setLoading(false)
        setImages(imagen)
        //setImages(res.data)
            
        } catch (err) {
            alert(err.response.data.msg)
        }
    }
    
    const handleDestroy = async () => {
        try {
            if(!isAdmin) return alert('Se requiere ser Administrador.');
            setLoading(true);

            await axios.post('/api/destroy', {public_id: images.public_id}, {
                headers: {Authorization: token}
            })
            setLoading(false);
            setImages(false);
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

  return (
    <div className='sliders'>
    <form onSubmit={createSlider}>
        <label htmlFor="slider">Banner</label>
        <input type="text" name='slid' value={slid} required 
        onChange={e => setSlid(e.target.value)}/>    
        <label htmlFor="tipo">Tipo</label>
        <input type="number" name='publicid' value={publicid} required 
        onChange={e => setPublicid(e.target.value)}/>
        {
            onEdit ? <button type='submit' className='actualizar'>Actualizar</button> :
            <button type='submit' className='guardar'>Crear</button>
        } 


        <div className="upload_slider">
            <input type="file" name='file' id='file_slider' onChange={handleUpload}/>
            {
                loading ? <div id="filie_img"><Loading /></div>
                :
                <div id="filie_img_slider" style={styleUpload}>
                    <img src={images ? images.url : ''} alt="" />
                    <span onClick={handleDestroy}>X</span>
                </div>
            }
        </div>    
    </form>

    <div className='col'>
        {
            sliders.map(slid => (
                <div className='row' key={slid._id}>
                    <p>{slid.name}</p>
                    <div>
                        <button className='edit' onClick={() => editCategoria(slid._id, slid.name, slid.images,slid.public)}>Editar</button>
                        <button onClick={() => deleteCategoria(slid._id)}>Eliminar</button>
                    </div>
                </div> 
            ))
        }
    </div>
</div>
  )
}

export default CreatePublic