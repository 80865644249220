import React, { useContext, useState, useEffect } from 'react'
import {useParams, Link} from 'react-router-dom';
import {GlobalState} from '../../../GlobalState';
import ProductItem from '../Utils/productItem/ProductItem';


function DetailProduct() {
    const params = useParams();
    const state = useContext(GlobalState);
    const [products] = state.productsAPI.products;
    const [promociones] = state.promocionesAPI.promociones;
    const addCart = state.userApi.addCart;
    const [detailProduct,setDetailProduct] = useState([]);
    const [encontrado, setEncontrado] = useState(false);

    useEffect(() => {
        if(params.id) {
            products.forEach(product => {
                if(product._id === params.id){
                    setDetailProduct(product)
                    setEncontrado(true);
                } 
            });
            if(!encontrado) {
                promociones.forEach(product => {
                    if(product._id === params.id) {
                        setDetailProduct(product)
                    }  
                }); 
            }
        }
    },[params.id]);

    if(detailProduct.length === 0) return null;

  return (
    <>
        <div className='detail'>
            <div className="promo">
                {
                    detailProduct.promotion === true ? <h3>{detailProduct.dto + '%'}</h3>: null
                }
                <img src={detailProduct.images.url} alt='' />
            </div>

            <div className='box-detail'>
                <div className='row'>
                    <h2>{detailProduct.title}</h2>
                    <h6>#id: {detailProduct.product_id}</h6>
                </div>
                <div className='row-description'>
                    <h2>{detailProduct.store}</h2>
                </div>               
                {
                    detailProduct.super_promo > 0 ? <span>${new Intl.NumberFormat().format(detailProduct.price-(detailProduct.price * detailProduct.super_promo)/100)}</span>
                    :
                        !encontrado ? 
                        <div className="precio">
                            <span>${new Intl.NumberFormat().format(detailProduct.price2)}</span> 
                            <span className="preci2">
                            <span className="antes">Antes/</span>${new Intl.NumberFormat().format(detailProduct.price)}</span>
                        </div>                            
                    : 
                        detailProduct.promotion === true ?
                        <div className="precio">
                            <span>${new Intl.NumberFormat().format(detailProduct.price-(detailProduct.price * detailProduct.dto)/100)}</span>
                            <span className="preci2"><span className="antes">Antes/</span>${new Intl.NumberFormat().format(detailProduct.price)}</span>
                        </div>
                        : <span>${new Intl.NumberFormat().format(detailProduct.price)}</span>
                }   
                <p>{detailProduct.description}</p> 
                <p>{detailProduct.content}</p> 
                <p>Vendidos: {detailProduct.sold}</p> 
                <Link to='/cart' className='cart' 
                    onClick={() => addCart(detailProduct)}>
                    Agregar al Carrito
                </Link>
            </div>
        </div>
        <div>
        <div className='textoinicial'>Productos Relacionados</div>
            {
                <div className='products'>
                    
                    {
                        products.map(product => {
                            return product.category === detailProduct.category 
                            ? <ProductItem key={product._id} product={product} cart={1}/> : null
                        })
                    }
                </div>
            }
         </div>
    </>
  )
};

export default DetailProduct;