import { createContext,useState,useEffect } from "react";
import ProductsAPI from './api/ProductsAPI';
import PromocionesAPI from './api/PromocionesAPI';
import SliderAPI from "./api/SliderAPI";
import CategoriesAPI from './api/CategoriesAPI';
import StoriesAPI from './api/StoreAPI';
import UserApi from './api/UserApi';
import ParametrosAPI from "./api/ParametrosAPI";
import DirectorioAPI from "./api/DirectorioAPI";
import axios from 'axios';


export const GlobalState = createContext();

export const DataProvider = ({children}) => {
    const [token, setToken] = useState(false);
    const [otp, setOTP] = useState('');


    useEffect(() => {   
        const firstLogin = localStorage.getItem('firstLogin');
        if(firstLogin) {
            const refreshToken = async () => {
                const res = await axios.get('/user/refresh_token');
                setToken(res.data.accesstoken);
    
                setTimeout(() => {
                    refreshToken()
                },10 * 60 * 1000)
            }
            refreshToken();
        }
    },[])

    const state = {
        token: [token, setToken],
        otp: [otp, setOTP],
        sliderAPI: SliderAPI(),
        productsAPI: ProductsAPI(),
        promocionesAPI: PromocionesAPI(),
        categoriesAPI: CategoriesAPI(),
        userApi: UserApi(token),
        storiesAPI: StoriesAPI(),
        parametrosAPI: ParametrosAPI(),
        directorioAPI: DirectorioAPI()
    }

    return (
        <GlobalState.Provider value={state}>
            {children}
        </GlobalState.Provider>
    )
};
