import {useState,useEffect} from 'react';
import axios from 'axios';


function ProductsAPI() {
    const [products, setProducts] = useState([]);
    const [callback, setCallback] = useState(false);
    const [category, setCategory] = useState('');
    const [sort, setSort] = useState('');
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [result, setResult] = useState(0);
    const [superpromo, setSuperPromo] = useState([]);
    const [fechaPromo, setFechaPromo] = useState('');

    useEffect(() => {
      const getProducts = async () => {     
          const res = await axios.get(`/api/products?&${category}&${sort}&title[regex]=${search}`);
          setProducts(res.data.products);
          setResult(res.data.result);   
        
          const promo = await axios.get(`/api/productspromo`); 
          setSuperPromo(promo.data);

          const fechapromo = await axios.get(`/api/fechapromo`); 
          setFechaPromo(fechapromo.data);
      }
      getProducts();
  },[callback,category, sort,search, page])

  return {
    products: [products, setProducts],
    callback: [callback, setCallback],
    category: [category, setCategory],
    sort: [sort, setSort],
    search: [search, setSearch],
    page: [page, setPage],
    result: [result, setResult],
    superpromo: [superpromo, setSuperPromo],
    fechaPromo: [fechaPromo, setFechaPromo]
  }
};

export default ProductsAPI;