import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';


function Login() {
  const [user, setUser] = useState({
    email:'',password:''
  });

  const onChangeInput = e => {
    const {name,value} = e.target;
    setUser({...user,[name]:value});

  }
  const loginSubmit = async e => {
    e.preventDefault();
    try {
      await axios.post('/user/login', {...user});
      
      localStorage.setItem('firstLogin', true);

      window.location.href = '/';

    } catch (err) {
      alert(err.response.data.msg)
    }

  }

  return (
    <div className="form-logueo">
      <div className='login-page'>
        <form onSubmit={loginSubmit}>
            <h2>Ingresar</h2>
            <input autoFocus type="email" name='email' required 
            placeholder='Email' value={user.email} onChange={onChangeInput} />
            <input type="password" name='password' required autoComplete='on'
            required placeholder='Password' value={user.password} onChange={onChangeInput}/>
            <Link to="/forgotPassword" style={{ alignSelf: "flex-start" }}>
							<p >Olvidó su contraseña ?</p>
						</Link>
            <div className="row">
              <button type='submit'>Ingresar</button>
              <Link to='/register'>Registrarse</Link>
            </div>
        </form>
      </div>
    </div>

  )
}

export default Login