import React, {useState,useContext,useEffect} from 'react';
import axios from 'axios';
import {GlobalState} from '../../../GlobalState';
import Loading from '../Utils/loading/Loading';
import {useNavigate, useParams} from 'react-router-dom'


const initialState = {
    product_id: '',
    title: '',
    price: 0,
    price2: 0,
    dto: 0,
    super_promo: 0,
    description: '',
    content: '',
    tallas: [],
    store: '',
    promotion: false,
    category: '',
    _id: ''
}

const CreateProduct = () => {
    const state = useContext(GlobalState);
    const [product, setProduct] = useState(initialState);
    const [store] = state.storiesAPI.stories;
    const [categories] = state.categoriesAPI.categories;
    const [images, setImages] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isAdmin] = state.userApi.isAdmin;
    const [isStore] = state.userApi.isStore;
    const [token] = state.token;
    const [isCheck, setisCheck] = useState(false);
    const history = useNavigate();
    const param = useParams();
    const [products] = state.productsAPI.products;
    const [talla, setTalla] = useState([]);
    const [user] = state.userApi.user;
    const [promociones] = state.promocionesAPI.promociones;
    const [onEdit, setOnEdit] = useState(false);
    const [callback, setCallback] = state.productsAPI.callback;
    const [callback2, setCallback2] = state.promocionesAPI.callback;

    const [encontrado, setEncontrado] = useState(false);
    const [tipoproducto, setTipoproducto] = useState("N/A");

    useEffect(() => {      
        if(param.id) {
            setOnEdit(true);
            products.forEach(product => {
                if(product._id === param.id) {
                    setProduct(product);
                    setImages(product.images);
                    setisCheck(product.promotion);
                    setTalla(product.tallas);
                    setEncontrado(true);
                }  
            });    
            if(!encontrado) {
                promociones.forEach(product => {
                    if(product._id === param.id) {
                        setProduct(product);
                        setImages(product.images);
                        setisCheck(product.promotion);
                        setTalla(product.tallas);
                    }  
                }); 
            }
        }else {           
            setOnEdit(false);
            setProduct(initialState);
            setImages(false);
        }
    },[param.id,products,encontrado,promociones])
 
const handleUpload = async e => {
    e.preventDefault();
    try {
        if(!isAdmin){
            if(!isStore){
                return alert('Se requiere ser Administrador.');
            }
        }      
        const file = e.target.files[0];
        
        if(!file) return alert('El archivo no existe.');

        if(file.size  > 1024 * 1024) 
            return alert('Tamaño de archivo muy grande.');

        if(file.type !== 'image/jpeg' && file.type !== 'image/jpg') 
            return alert('El formato de archivo es incorrecto.')

        let formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', 'c9mchxoy')


        setLoading(true);
        const cloud_name = '';
        // const res = await axios.post('/api/upload', formData, {
        //     headers: {'content-type': 'multipart/form-data', Authorization: token}
        const res2 = await axios.post('/api/upload',cloud_name, {
        headers: {Authorization: token}})

        const res = await axios.post(`https://api.cloudinary.com/v1_1/${res2.data}/image/upload`, formData);  

        const imagen = {
            public_id: res.data.public_id,
            url: res.data.secure_url
            };
        //  const aaaa = JSON.stringify(imagen)
        setLoading(false)
        setImages(imagen)
        //setImages(res.data)
    } catch (err) {
        alert(err.response.data.msg)
    }
}

const handleDestroy = async () => {
    try {
        if(!isAdmin){
            if(!isStore){
                return alert('Se requiere ser Administrador.');
            }
        }
        await axios.post('/api/destroy', {public_id: images.public_id}, {
            headers: {Authorization: token}
        })

        setImages(false);
    } catch (err) {
        alert(err.response.data.msg)
    }
}

const handleChangeInput = e => {
    const {name, value} = e.target
    setProduct({...product, [name]:value})      
}

const handleChangeTipoTalla = e => {
    if(!onEdit) setTalla([]);
    setTipoproducto(e.target.value)      
}

const handleChangeChecked = e => {
    setisCheck(e.target.checked)
    const name = e.target.name
    setProduct({...product, [name]:isCheck})   
}

const handleSubmit = async  e => {
    e.preventDefault();
    try {  
        if(!isAdmin){
            if(!isStore){
                return alert('Se requiere ser Administrador.');
            }
        }
             
        if(!images) return alert('Aún no ha cargado la imagen del Producto.');
        if(product.store === '') return alert('Debe seleccionar el nombre del almacén.')
        if(product.category === '') return alert('Debe seleccionar una Categoria.');

        product.promotion = isCheck;
        if(talla !== null) product.tallas = talla;

        if(onEdit) {
                await axios.put(`/api/products/${product._id}`, {...product, images}, {
                    headers: {Authorization: token}
                });
        }else {
                await axios.post('/api/products', {...product, images}, {
                    headers: {Authorization: token}
                });
        }
        setCallback(!callback);
        setCallback2(!callback2);

        history('/');               
    } catch (err) {
        alert(err.response.data.msg)
    }
}

const styleUpload = {
    display: images ? 'block' : 'none'
}

  return (
    <div className='create_product'>
        <div className="upload">
            <input type="file" name='file' id='file_up' onChange={handleUpload}/>
            {
                loading ? <div id="filie_img"><Loading /></div>
                :            
                <div id="filie_img" style={styleUpload}>
                    <img src={images ? images.url : ''} alt="" />
                    <span onClick={handleDestroy}>X</span>
                </div>
            }
        </div>
        <form onSubmit={handleSubmit}>
            <div className="row">
                <label htmlFor="product_id">ID Producto</label>
                <input type="text" name='product_id' id='product_id' required
                value={product.product_id} onChange={handleChangeInput} disabled={product._id}/>
            </div>

            <div className="row">
                <label htmlFor="title">Título</label>
                <input type="text" name='title' id='title' required
                value={product.title} onChange={handleChangeInput}/>
            </div>

            <div className="row">
                <label htmlFor="price">Precio</label>
                <input type="number" name='price' id='price' required
                value={product.price} onChange={handleChangeInput}/>
            </div>

            <div className="row">
                <label htmlFor="price2">Precio Remate</label>
                <input type="number" name='price2' id='price2' required
                value={product.price2} onChange={handleChangeInput}/>
            </div>

            <div className="row">
                <label htmlFor="dto">% dto. Producto</label>
                <input type="number" name='dto' id='dto' required
                value={product.dto} onChange={handleChangeInput}/>
            </div>
            <div className="row">
                {
                    isAdmin ? 
                    <>
                        <label htmlFor="price_conter">% dto. Super promoción</label>
                        <input type="number" name='super_promo' id='super_promo ' required
                        value={product.super_promo} onChange={handleChangeInput}/>
                    </>
                    :null
                }
            </div>
            <div className="row">
                <label htmlFor="description">Descripción</label>
                <textarea type="text" name='description' id='description' required
                value={product.description} rows='5' onChange={handleChangeInput}/>
            </div>

            <div className="row">
                <label htmlFor="content">Contenido</label>
                <textarea type="text" name='content' id='content' required
                value={product.content} rows='7' onChange={handleChangeInput}/>
            </div>
            <div className="tipoproducto">
                <label>Selecione el tipo de producto</label>
            </div>
            <div className="Opciones" onChange={handleChangeTipoTalla}>
                <input type="radio" value="N/A" name="gender" defaultChecked /> N/A
                <input type="radio" value="Ropa" name="gender" /> Ropa
                <input type="radio" value="Calzado" name="gender" /> Calzado
            </div>
            <div className="talla">
                { 
                    tipoproducto !== "N/A" ? 
                        <label>Selecione las tallas</label>
                    : null
                }
                {
                    tipoproducto === "Calzado" ? 
                    <>
                    <select
                        multiple={true}
                        value={talla}
                        onChange={e => {
                            const options = [...e.target.selectedOptions];
                            const values = options.map(option => option.value);
                            setTalla(values);
                        }}
                        >
                        <option value="35">35</option>
                        <option value="36">36</option>
                        <option value="37">37</option>
                        <option value="38">38</option>
                        <option value="39">39</option>
                        <option value="40">40</option>
                        <option value="41">41</option>
                        <option value="42">42</option>
                    </select>
                    <select
                        multiple={true}
                        value={talla}
                        onChange={e => {
                            const options = [...e.target.selectedOptions];
                            const values = options.map(option => option.value);
                            setTalla(values);
                        }}
                        >
                        <option value="6.5">6.5</option>
                        <option value="7">7</option>
                        <option value="7.5">7.5</option>
                        <option value="8">8</option>
                        <option value="8.5">8.5</option>
                        <option value="9">9</option>
                        <option value="9.5">9.5</option>
                        <option value="10">10</option>
                    </select>
                    </>
                    : tipoproducto === "Ropa" ?
                        <select
                            multiple={true}
                            value={talla}
                            onChange={e => {
                                const options = [...e.target.selectedOptions];
                                const values = options.map(option => option.value);
                                setTalla(values);
                            }}
                            >
                            <option value="XS">XS</option>
                            <option value="S">S</option>
                            <option value="M">M</option>
                            <option value="L">L</option>
                            <option value="XL">XL</option>
                            <option value="U">U</option>
                        </select>
                        :
                        null
                }
            </div>
            <div className="talla2">
                {
                    talla.length > 0 ?
                    <span>Tallas seleccionadas</span>
                    : null
                }                
                {
                    talla.map(ta => (
                        <div key={ta}>
                            <h3 style={{background: 'gray'}}>{ta}</h3>
                        </div>
                    ))
                }
            </div>
            <div className="row">
                <label htmlFor="store">Almacén :</label>
                {
                    isAdmin ? 
                    <select name="store" value={product.store} onChange={handleChangeInput}>
                    <option value="">Seleccione un Almacén</option>
                    {
                        store.map(story => (
                            <option value={story.name} key={story._id}>
                                {story.name}
                            </option>
                        ))
                    }
                    </select>
                    :
                    <select name="store" value={product.store} onChange={handleChangeInput}>
                    <option value="">Seleccione un Almacén</option>
                    <option value={user.name}>{user.name}</option>
                    </select>

                    // <span>{user.name}</span>
                }
            </div>

            <div className="row2">
                <label htmlFor="promotion">Promoción</label>
                <input type="checkbox" checked={isCheck}  name='promotion' id='promotion'  
                value={product.promotion} onChange={handleChangeChecked}/>
            </div>
            
            <div className="row">
                <label htmlFor="categories">Cagetoría :</label>
                <select name="category" value={product.category} onChange={handleChangeInput}>
                    <option value="">Selecciona una Categoría</option>
                    {
                        categories.map(category => (
                            <option value={category._id} key={category._id}>
                                {category.name}
                            </option>
                        ))
                    }
                </select>
            </div>
            <button type='submit'>{onEdit ? 'Actualizar Producto' : 'Crear Producto'}</button>
        </form>
    </div>
  )
}

export default CreateProduct