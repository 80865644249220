import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';


function Register() {
  const [user, setUser] = useState({
    name:'', email:'',password:''
  });

  const onChangeInput = e => {
    const {name,value} = e.target;
    setUser({...user,[name]:value});
  }

  const registerSubmit = async e => {
    e.preventDefault();
    try {
      // console.log('hola')
      await axios.post('/user/register', {...user});

      localStorage.setItem('firstLogin', true);
      
      window.location.href = '/';

    } catch (err) {
      alert(err.response.data.msg)
    }
  }

  return (
    <div className="form-logueo">
      <div className='login-page'>
        <form onSubmit={registerSubmit}>
            <h2>Registrarse</h2>
            <input autoFocus type="text" name='name' required 
            placeholder='Name' value={user.name} onChange={onChangeInput} />

            <input type="email" name='email' required 
            placeholder='Email' value={user.email} onChange={onChangeInput} />
            <input type="password" name='password' required autoComplete='on'
            required placeholder='Password' value={user.password} onChange={onChangeInput}/>
            <div className="row">
              <button type='submit'>Registrarse</button>
              <Link to='/login'>Loguearse</Link>
            </div>
        </form>
      </div>
    </div>
  )
}

export default Register