import React, {useContext} from 'react';
import { GlobalState } from '../../../../GlobalState';
import './publicidad.css'

const Publicidad = ({tipo,stilo}) => {

  const state = useContext(GlobalState);

  const [sliders] = state.sliderAPI.sliders;

    return (
      <>
      {
        stilo === 0 ? 
        <div className='item'>
            {                    
                sliders.map(slide => {
                    if(slide.public === tipo ) 
                    return <img src={slide.images.url} key={slide._id} alt='' />
                   
                })
            } 
        </div>
        :stilo === 1 ?
          <div className='item2'>
            {                    
                sliders.map(slide => {
                  if(slide.public === tipo ) 
                  return <img src={slide.images.url} key={slide._id} alt='' />
                })
            } 
          </div>
        :
          <div className='item3'>                       
          {                    
              sliders.map(slide => {
                if(slide.public === tipo ) 
                return <img src={slide.images.url} key={slide._id} alt='' />
              })
          } 
          </div>         
      }
      </>
    )
};

export default Publicidad;