import React, {useState,useContext,useEffect} from 'react';
import axios from 'axios';
import { GlobalState } from '../../../GlobalState';
// import { useParams } from 'react-router-dom';
import '../parametrosGenerales/parametrosGenerales.css';


const initialState = {
    name: '',
    valor: ''
}

const ParametrosGenerales = () => {
    const state = useContext(GlobalState);
    const [parametros] = state.parametrosAPI.parametros;
    const [callback, setCallback] = state.parametrosAPI.callback;
    const [callback2, setCallback2] = state.productsAPI.callback;

    const [generales, setGenerales] = useState(initialState);
    const [name, setName] = useState('');
    const [valor, setValor] = useState('');
    const [onEdit, setOnEdit] = useState(false);
    const [id,setID] = useState('');
    const [isAdmin] = state.userApi.isAdmin;
    const [token] = state.token;

    const editParametro = async(id,name,valor) => {
        setID(id);
        setName(name);
        setValor(valor);
        setOnEdit(true);
    }
    
    const handledSubmit = async e => {
        e.preventDefault();
        try {
            if(!isAdmin) return alert('Se requiere ser administrador.');
     
            if(onEdit){
                const res = await axios.put(`/api/parametros/${id}`, {name: name,valor: valor}, {
                    headers: {Authorization: token}
                });
                // alert(res.data.msg);
            }else {
                const res = await axios.post('api/parametros',{...generales}, {
                    headers: {Authorization: token}
                });
                alert(res.data.msg);
            }
            setOnEdit(false);
            setName('');
            setID('');
            setValor('');
            setCallback(!callback);
            setCallback2(!callback2);
        } catch (err) {
            alert(err.response.data.msg)
        }
    }
    
    const handleChangeInput = e => {
        const {name, value} = e.target
        setGenerales({...generales, [name]:value})      
    }

  return (
    <div className='create_parametros'>
        <div className="lista">
            <div id='list'>
                <table className="table">
                    <thead>
                        <tr>
                        <th>Parámetro</th>
                        <th>Valor</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            parametros.map(para => (
                                <tr key={para._id}>
                                    <td>{para.name}</td>
                                    <td>{para.valor}</td>
                                    <td><button className='edit' onClick={() => editParametro(para._id, para.name, para.valor)}>Editar</button></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
        <form onSubmit={handledSubmit}>
            <div className="row">
                <label htmlFor="name">Parámetro</label>
                <input type="text" name='name' id='name' required value={name}
                onChange={e => setName(e.target.value)}/>
            </div>
            <div className="row">
                <label htmlFor="valor">Valor</label>
                <input type="text" name='valor' id='valor' required value={valor}
                onChange={e => setValor(e.target.value)}/>
            </div>
            <button type='submit'>{onEdit ? 'Actualizar Parámetro' : 'Crear Parámetro'}</button>
        </form>
    </div>
  )
}

export default ParametrosGenerales