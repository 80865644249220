import React, {useState, useEffect} from 'react';
import axios from 'axios';


function SliderAPI()  {
    const [callback, setCallback] = useState(false)
    const [sliders, setSliders] = useState([]);
    
  useEffect(() => {
    const getSliders = async () =>{
      const res = await axios.get('/api/slider');
      setSliders(res.data);
        }
        getSliders();
},[callback]);

  return {
    sliders: [sliders, setSliders],
    callback: [callback, setCallback]
  }
}

export default SliderAPI





