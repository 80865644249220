import React from 'react';
import  './slideItem.css'

const SlideItem = ({slide}) => {
  return (
        <div className='slide'>
            <img src={slide.images.url} alt='' />
        </div>
  )
}

export default SlideItem
