import React from 'react'
import Logotienda from '../../../footer/img/logotiendavirtual.jpg'

function NotFound() {
  return (
    <div className='nofount'>
        <img src={Logotienda} alt='' width='400' />
        <h1>La página no existe.</h1>
    </div>
  )
}

export default NotFound