import React,{useState, useContext} from 'react'
import { GlobalState } from '../../../GlobalState';
import axios from 'axios';
import Loading from '../Utils/loading/Loading';


const Categories = () => {
    const state = useContext(GlobalState);
    const [categories] = state.categoriesAPI.categories;
    const [category, setCategory] = useState('');
    const [token] = state.token;
    const [callback, setCallback] = state.categoriesAPI.callback
    const [onEdit, setOnedit] = useState(false);
    const [id,setID] = useState('');
    const [images, setImages] = useState(false);
    const [isAdmin] = state.userApi.isAdmin;
    const [loading, setLoading] = useState(false);

    const imagen = {
        public_id: "",
        url: ""
    }

    const styleUpload = {
        display: images ? 'block' : 'none'
    }

const createCategory = async e => {
    e.preventDefault();
    try {
        if(onEdit) {
            if(!images) return alert('No se ha cargado la imagen.');
            const res = await axios.put(`/api/category/${id}`, {name: category,images: images}, {
                headers: {Authorization: token}
            });
            alert(res.data.msg);
        }else {
            const res = await axios.post('/api/category', {name: category,images: images}, {
                headers: {Authorization: token}
            });
            alert(res.data.msg);

        }
        setOnedit(false);
        setCategory('');
        setCallback(!callback);
        setImages(false);
    } catch (err) {
        alert(err.response.data.msg);
    }
}

const editCategoria = async(id, name, images) => {
    setID(id);
    setCategory(name);
    setImages(images);
    setOnedit(true);
}


const deleteCategoria = async id => {
    try {
        const res = await axios.delete(`/api/category/${id}`,{
            headers: {Authorization: token} 
        });
        alert(res.data.msg);
        setCallback(!callback);
        setOnedit(false);
    } catch (err) {
        alert(err.response.data.msg);
    }
} 

const handleUpload = async e => {
    e.preventDefault();
    try {
        if(!isAdmin) return alert('Se requiere ser Administrador.');
        const file = e.target.files[0];
        
        if(!file) return alert('El archivo no existe.');

        if(file > 1024 * 1024) 
            alert('Tamaño de archivo muy grande.');

        if(file.type !== 'image/jpeg' && file.type !== 'image/jpg') 
            return alert('El formato de archivo es incorrecto.')

        let formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', 'c9mchxoy')

        setLoading(true)
        const cloud_name = '';
        // const res = await axios.post('/api/upload', formData, {
        //     headers: {'content-type': 'multipart/form-data', Authorization: token}
        const res2 = await axios.post('/api/upload',cloud_name, {
        headers: {Authorization: token}})

        const res = await axios.post(`https://api.cloudinary.com/v1_1/${res2.data}/image/upload`, formData);  

        const imagen = {
            public_id: res.data.public_id,
            url: res.data.secure_url
            };
        //  const aaaa = JSON.stringify(imagen)
        setLoading(false)
        setImages(imagen)


        // formData.append('file', file);

        // setLoading(true)
        // const res = await axios.post('/api/upload', formData, {
        //     headers: {'content-type': 'multipart/form-data', Authorization: token}
        // })
        // setLoading(false)
        // setImages(res.data)
        
    } catch (err) {
        alert(err.response.data.msg)
    }
}

const handleDestroy = async () => {
    try {
        if(!isAdmin) return alert('Se requiere ser Administrador.');
        setLoading(true);
        // console.log(images.public_id)
        await axios.post('/api/destroy', {public_id: images.public_id}, {
            headers: {Authorization: token}
        })
        setLoading(false);
        setImages(false);
    } catch (err) {
        alert(err.response.data.msg)
    }
}

  return (
    <div className='categories'>
        <form onSubmit={createCategory}>
            <label htmlFor="category">Categorías</label>
            <input type="text" name='category' value={category} required 
            onChange={e => setCategory(e.target.value)}/>
            {
                onEdit ? <button type='submit' className='actualizar'>Actualizar</button> :
                <button type='submit' className='guardar'>Crear</button>
            }     
            <div className="upload_category">
                <input type="file" name='file' id='file_category' onChange={handleUpload}/>
                {
                    loading ? <div id="filie_img"><Loading /></div>
                    :
                    <div id="filie_img_category" style={styleUpload}>
                        <img src={images ? images.url : ''} alt="" />
                        <span onClick={handleDestroy}>X</span>
                    </div>
                }
            </div>    
        </form>

        <div className='col'>
            {
                categories.map(category => (
                    <div className='row' key={category._id}>
                        <p>{category.name}</p>
                        <div>
                            <button className='edit' onClick={() => editCategoria(category._id, category.name, category.images)}>Editar</button>
                            <button onClick={() => deleteCategoria(category._id)}>Eliminar</button>
                        </div>
                    </div> 
                ))
            }
        </div>
    </div>
  )
}

export default Categories