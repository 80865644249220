import React, {useState,useEffect,useContext} from 'react'
import {useParams} from 'react-router-dom';
import {GlobalState} from '../../../GlobalState';
import '../history/orderDetail.css';

const OrderDetail = () => {
    const state = useContext(GlobalState);
    const [history] = state.userApi.history;
    const [orderDetail, setOrderDetail] = useState([]);
    const [isStore] = state.userApi.isStore;
    const [user] = state.userApi.user;

    const params = useParams();

    useEffect(() => {
        if(params.id) {
            history.forEach(item => {
                if(item._id === params.id) setOrderDetail(item)
            })
        }
    },[params.id, history]);

if(orderDetail.length === 0) return null;
    
  return (
    <div className='history-pages'>
        <table>
            <thead>
                <tr>
                    <th>Nombre</th>
                    <th>Dirección</th>
                    <th>Observación</th>
                    {/* <th>Código postal</th>
                    <th>Código del pais</th>      */}
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{orderDetail.address.recipient_name}</td> 
                    <td>{orderDetail.address.line1 + ' - Tel: ' + orderDetail.address.telephone}</td>
                    <td>{orderDetail.address.message}</td>
                    {/* <td>{orderDetail.address.postal_code}</td>
                    <td>{orderDetail.address.country_code}</td> */}
                </tr>
            </tbody>
        </table>
        <table style={{margin: '30px 0px'}}>
            <thead>
                <tr>
                    <th>Producto</th>
                    <th>Nombre</th>
                    <th>Almacén</th>
                    <th>Cantidad</th>
                    <th>Total</th>     
                </tr>
            </thead>
            <tbody>
                {
                    orderDetail.cart.map(item => (
                        <tr key={item._id}>
                            {
                                isStore ?
                                    item.store === user.name ? 
                                    <>
                                    <td><img src={item.images.url} alt=''/></td> 
                                    <td>{item.title}</td>
                                    <td>{item.store}</td>
                                    <td>{item.quantity}</td>
                                    {
                                        item.super_promo > 0 ? 
                                        <td>${new Intl.NumberFormat().format((item.price - ((item.price * item.super_promo)/100)) * item.quantity)}</td>
                                        :
                                        item.promotion === true ?
                                        <td>${new Intl.NumberFormat().format((item.price -((item.price * item.dto)/100)) * item.quantity)}</td>
                                        :
                                        item.price2 > 0 ?<td>${new Intl.NumberFormat().format(item.price2 * item.quantity)}</td>
                                        :<td>${new Intl.NumberFormat().format(item.price * item.quantity)}</td>
                                    }
                                    </>
                                    :null
                                :
                                <>
                                <td><img src={item.images.url} alt=''/></td> 
                                <td>{item.title}</td>
                                <td>{item.store}</td>
                                <td>{item.quantity}</td>
                                {
                                    item.super_promo > 0 ? 
                                    <td>${new Intl.NumberFormat().format((item.price - ((item.price * item.super_promo)/100)) * item.quantity)}</td>
                                    :
                                    item.promotion === true ?
                                    <td>${new Intl.NumberFormat().format((item.price -((item.price * item.dto)/100)) * item.quantity)}</td>
                                    :
                                    item.price2 > 0 ?<td>${new Intl.NumberFormat().format(item.price2 * item.quantity)}</td>
                                    :<td>${new Intl.NumberFormat().format(item.price * item.quantity)}</td>
                                }
                                </>

                            }                           
                        </tr>
                    ))
                }
            </tbody>
        </table>
    </div>
  )
}

export default OrderDetail