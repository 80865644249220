import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Swal from "sweetalert2";
import Notificacion from '../components/mainpages/Utils/alert/Notificacion';

function UserApi(token) {
    const [isLoggued, setIsLogged] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isStore, setIsStore] = useState(false);
    const [namestore, setNameStore] = useState('');
    const [cart, setCart] = useState([]);
    const [history, setHistory] = useState([]);
    const [user, setUser] = useState([]);


    useEffect(() => {
        if(token){
            const getUser = async () => {
                try {
                    const res = await axios.get('/user/infor', {
                        headers: {Authorization: token}              
                    })
                    setIsLogged(true);
                    res.data.role === 1 ? setIsAdmin(true) 
                    : setIsAdmin(false) 
                    
                    res.data.role === 2 ? setIsStore(true) 
                    : setIsStore(false);

                    setCart(res.data.cart);

                    setUser(res.data);
                } catch (err) {
                    alert(err.response.data.msg);
                }
            }
            getUser();
        }      
    },[token]);

    useEffect(() => {
        if(token) {
            const getHistory = async () => {
                if(isAdmin || isStore){
                    const res = await axios.get('/api/payment', {
                        headers: {Authorization: token}
                    }); 
                    setHistory(res.data);
                }else{
                    const res = await axios.get('/user/history', {
                        headers: {Authorization: token}
                    });
    
                    setHistory(res.data);
                }
            }
            getHistory();
        }
    },[token,isAdmin,isStore])

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
    
      const llamar = (tipo,texto) => {
        Toast.fire({
          icon: tipo,
          title: texto
        })
      }

      const llamar2 = (titulo) => {
        Swal.fire({
            // title: 'Debes loguearte para realizar compras',
            html: titulo,
            type: 'success',
            confirmButtonColor: '#f96d00',
            confirmButtonText: "Aceptar",
          })
      }

    const addCart = async (product) => {
        if(!isLoggued) return llamar2('<h3>Debes loguearte para realizar compras</h3>');
        
        // <Notificacion alerta={2} tipo={''} texto={''} titulo={'<h3>Debes loguearte para realizar compras</h3>'}/>
        
        
        // llamar2('<h3>Debes loguearte para realizar compras</h3>');
        
        // 
        
        
        
        // Swal.fire('Debes loguearte para realizar compras');
        // alert('Debes loguearte para realizar compras.');

        const check = cart.every(item => {
            return item._id !== product._id;
        });

        if(check){
            setCart([...cart,{...product, quantity: 1}]);

            await axios.patch('/user/addcart', {cart: [...cart,{...product, quantity: 1}]}, {
                headers: {Authorization: token}
            });
            llamar('success','Producto agregado al carrito');
        }else {
            // alert('Este Producto ya está adiconado al carrito.');

            llamar2('<h3>Este Producto ya está adiconado al carrito</h3>');


        }
        
    }

  return {
    isLoggued: [isLoggued, setIsLogged],
    isAdmin: [isAdmin, setIsAdmin],
    isStore: [isStore, setIsStore],
    cart: [cart, setCart],
    addCart: addCart,
    history: [history, setHistory],
    user: [user, setUser]
    }
}

export default UserApi