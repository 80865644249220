// import React, { useState } from "react";
import { Slide } from "react-slideshow-image";
import '../slideShow2/slidecategory.css'



import React, {useState,useContext} from 'react';
import { GlobalState } from '../../GlobalState';
// import 'react-slideshow-image/dist/styles.css'
import SlideItem2 from '../mainpages/Utils/slideItem2/SlideItem2';


const Slideshow2 = () => {

    const state = useContext(GlobalState);

    const [categories] = state.categoriesAPI.categories;

  const style = {
    textAlign: 'center',
    background: 'teal',
    padding: '200px 0',
    fontSize: '30px',
    display: 'flex',
  };

  const properties = {
    duration: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    indicators: true,
    responsive: [
        {
            breakpoint: 1600,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
          breakpoint: 380,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 1
          }
      }
    ]
  };

  return (
    <div>
        <Slide {...properties}>
            {
                  categories.map(category => {
                    return <SlideItem2 key={category._id} category1={category}/>
                })
            }
        </Slide>
    </div>
  );
};

export default Slideshow2;