import React, {useContext, useState, useEffect} from 'react'
import {GlobalState} from '../../../GlobalState';
import axios from 'axios';
import PaypalButton from './PaypalButton';
import {useNavigate} from 'react-router-dom'

import {Link} from 'react-router-dom';

function Cart() {

  const state = useContext(GlobalState);
  const [cart, setCart] = state.userApi.cart;
  const [token] = state.token;
  const [total, setTotal] = useState(0);
  // const [talla, setTalla] = useState('');
  // const [valor, setValor] = useState(0);
  const [tallasencontradas, setTallasEncontradas] = useState(0);
  const [tallascompradas, setTallasCompradas] = useState(0);
  // const [productseleccionados, setProdutseleccionados] = useState([]);
  const Contacto = useNavigate();


  const TallasCreadas = {
    IdProducto: '',
    Talla: ''
  }


  useEffect(() => {
    const Tallas = () => {
      let dato = 0;
      cart.forEach(item => {
        if(item.tallacomprada){
          setTallasCompradas(dato += 1)
        }     
      });
      ValidarSiEligieronTallas(1);
    }
    Tallas();
  },[]) 


  useEffect(() => {
    const getTotal = () => {
      const total = cart.reduce((prev, item) => {
        if(item.super_promo > 0){
          return prev + ((item.price - ((item.price * item.super_promo)/100)) * item.quantity);
        }else{
          if(item.promotion === true){
            return prev + ((item.price -((item.price * item.dto)/100)) * item.quantity);
          }else{
            if(item.price2 > 0){
              return prev + (item.price2 * item.quantity);
            }else {
              return prev + (item.price * item.quantity);
            }
          }  
        } 
      },0)
      setTotal(total);
      
    } 
    getTotal();
  },[cart]);

  const addToCart = async (cart) => {
    await axios.patch('/user/addcart',{cart}, {
      headers: {Authorization: token}
    });
  };

  const pagar = () =>{
      if(tallascompradas === tallasencontradas){
        Contacto('/contacto'); 
      } else {
        return alert('Debe seleccionar las tallas de los productos a comprar.');
      }   
    }

  const ValidarSiEligieronTallas = (tipo) =>{
  let dato = 0
    cart.forEach(item => {
    if(item.tallas.length > 0) {
          setTallasEncontradas(dato += 1)
      }
    });
  }

  const ValidarTallasCompradas = (id,ta) => {
    let dato = 0
    cart.forEach(item => {
      if(item.tallacomprada){
        setTallasCompradas(dato += 1)
        // TallasCreadas.IdProducto = id
        // TallasCreadas.Talla = ta
        // const newData = [...productseleccionados, TallasCreadas]
        // setProdutseleccionados(newData)
      }     
    });
    
  }

  const agregarTalla = (id,ta) => {
    let dato = tallascompradas;
      if(tallascompradas < 3){
        setTallasCompradas(dato += 1)
      }


    // setTalla(ta);
    cart.forEach(item => {
      if(item._id === id) {        
        // setValor(valor + 1)
        item.tallacomprada = ta;
      }
    });
    setCart([...cart]);
    addToCart(cart);
  }

  const increment = (id) =>{
    cart.forEach(item => {
      if(item._id === id) {
        item.quantity += 1;
      }
    });
    setCart([...cart]);
    addToCart(cart);
  };

  const decrement = (id) =>{
    cart.forEach(item => {
      if(item._id === id) {
        item.quantity === 1 ? item.quantity = 1 : item.quantity -= 1;
      }
    });
    setCart([...cart]);
    addToCart(cart);
  };

  const removeProduct = (id) => {
    if(window.confirm('Desea eliminar este producto de la lista?')) {
      cart.forEach((item, index) => {
        if(item._id === id) {
          cart.splice(index, 1)
        }
      });
      setCart([...cart]);
      addToCart(cart);
    }
  };

  const tranSuccess = async(payment) => {

    const {paymentID, address} = payment;
    const idconsecutivoventa = '';

    await axios.post('/api/payment', {cart, paymentID, address}, {
      headers: {Authorization: token}
    })   
    setCart([]);
    addToCart([]);
    alert('Su orden de compra fue realizada con éxito.');
  };

  return (
    <>
      {
          cart.length === 0 ? 
          <div className='carrito_vacio'>
            <h2 className='carrito' >Carrito Vacio</h2>
            <Link id='comprar' to={`/`}>
              Ir a los productos
            </Link>
          </div>
        :
        <div className='cart'>
        {       
          cart.map(product => (
                  <div className='detail cart' key={product._id}>
                    <img src={product.images.url} alt='' className='img_container'/>
                      <div className='box-detail'>                     
                        <h2>{product.title}</h2>
                        <div className='row-description'>
                            <h2>{product.store}</h2>
                        </div>
                        {
                          product.super_promo > 0 ? 
                          <span>${new Intl.NumberFormat().format((product.price - ((product.price * product.super_promo)/100))  * product.quantity)}</span>
                          :
                          product.promotion === true ?
                          <span>${new Intl.NumberFormat().format((product.price-(product.price * product.dto)/100) * product.quantity)}</span>
                          :
                          product.price2 > 0 ? <h3>${new Intl.NumberFormat().format(product.price2 * product.quantity)}</h3>
                          :<h3>${new Intl.NumberFormat().format(product.price * product.quantity)}</h3>
                        }                      
                        <p>{product.description}</p> 
                        <p>{product.content}</p> 
  
                        <div className="amount">
                          <button onClick={() => decrement(product._id)}> - </button>
                          <span>{product.quantity}</span>
                          <button onClick={() => increment(product._id)}> + </button>
                        </div>
                        <div className="tallas">
                          {
                              product.tallas.length > 0 ?                             
                              <span>Selecciona la talla</span>
                              : null
                          }  
                          {
                            product.tallas.map(ta => (
                              <div key={ta}>
                                <button className='grupotallas' //{ta === talla ? 'active' : ''}
                                onClick={() => agregarTalla(product._id,ta)}>{ta}</button>
                              </div>
                            ))
                          }
                        </div>
                          {
                            product.tallacomprada ?                                                         
                              <div className="tallaseleccionada">
                                <span>Talla seleccionada</span>
                                <label>{product.tallacomprada}</label>
                                <Link id='politica' to={`/envio`}>
                                  Ver Política de envio
                                </Link>
                              </div>
                              : null                           
                          }                         
                        <div className="delete" onClick={() => 
                          removeProduct(product._id)}>
                            X
                        </div>
                      </div>
                  </div>
                ))
        }
        <div className="total">
          <h3>Total:  ${new Intl.NumberFormat().format(total)}</h3>
          {/* <div className='paypay'>
            <PaypalButton total={total} tranSuccess={tranSuccess} /> 
          </div> */}
            {/* <Link to='/contacto'>Pago contra entrega</Link> */}
            <button onClick={pagar}><span>Pago contra entrega</span></button> 
        </div>

      </div>
      }
    </>

  )
}

export default Cart