import React, {useContext,useState} from 'react';
import {GlobalState} from '../../GlobalState';
import Menu from './icon/menu.svg';
import Close from './icon/close.svg';
import Cart from './icon/cart.svg';
import User from './icon/user.svg';
import Logotienda from './icon/comprandoenbello.png';
import Premio from './icon/premio.png';
import {Link} from "react-router-dom";
import axios from 'axios';


function Header() {
  const state = useContext(GlobalState);
  const [isLoggued] = state.userApi.isLoggued;
  const [isAdmin] = state.userApi.isAdmin;
  const [isStore] = state.userApi.isStore;
  const [cart] = state.userApi.cart;
  const [menu, setMenu] = useState(false);

  const logoutUser = async () => {
    await axios.get('/user/logout');

    localStorage.removeItem('firstLogin');

    window.location.href = '/';
  }

  // const toggleMenu = () => setMenu(!menu);

  const adminRouter = () => {
    return(
     <>
      <li><Link to='/create_product'>Crear Producto</Link></li>
      <li><Link to='/category'>Categorías</Link></li>
      <li><Link to='/Create_slider'>Crear Banner</Link></li>
      <li><Link to='/Create_parametros'>Parámetros</Link></li>
     </>
    )
  }
  const loggedRouter = () => {
    return(
     <>
     {
        isStore ?
          <li><Link to='/create_product' onClick={() => setMenu(!menu)}>Crear Producto</Link></li>
        :null 
     }    
      <li><Link to='/history' onClick={() => setMenu(!menu)}>Historial</Link></li>
      <li><Link to='/' onClick={logoutUser}>Cerrar Sesión</Link></li>
     </>
    )
  }

const styleMenu = {
  left: menu ? '-20%' : '-100%'
}

  return (
    <header>
      <div className='menu' onClick={() => setMenu(!menu)}>
        <img src={Menu} alt='' width='30'/>
      </div>
      <div className='logo'>
          <h1>
            <Link to='/'>{isAdmin ? 'Administrador' :
              <img src={Logotienda} alt='' width='250' />}
            </Link>
          </h1>
      </div>
      <ul style={styleMenu}>
        <li><Link to='/home' onClick={() => setMenu(!menu)}>Inicio</Link></li>
        <li><Link to='/Directorio'>Directorio</Link></li>
        <li><Link to='/servicio'>Servicios</Link></li>
        <li><Link to='/' onClick={() => setMenu(!menu)}>Productos</Link></li>
          {isAdmin && adminRouter()}
          {
            isLoggued ? loggedRouter() : <li><Link to='/login'><img src={User} onClick={() => setMenu(!menu)} alt='' width='25'/> </Link></li>
          }
        <li onClick={() => setMenu(!menu)}>
          {/* <img src={Close} alt='' width='30' className='menu' /> */}
          <p className='salir' >Salir</p>
        </li>
      </ul>
      {
        isAdmin ? '' :
                    <div className='cart-icon'>
                      <span>{cart.length}</span>
                      <Link to='/cart'>
                        <img src={Cart} alt='' width='30' />
                      </Link>
                    </div>
      }

    </header>
  )
};

export default Header