import React, {useState,useEffect} from 'react'
import { FaAngleUp } from 'react-icons/fa';

const BackToTopButton = ({top}) => {
    const [backToTopButton, setBackToTopButton] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
          if(window.scrollY > 500){
            setBackToTopButton(true);
          }else {
            setBackToTopButton(false);
          }
        } )
      },[])

      const scrollup = () => {
        window.scrollTo({
          top: top,
          behavior: 'smooth'
        })
      }

  return (
    <div>
        {backToTopButton && (
            <FaAngleUp className='top-to-btm' onClick={scrollup}>Ʌ</FaAngleUp>
        )}
        
    </div>
  )
}

export default BackToTopButton