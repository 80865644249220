import React, {useContext,useState} from 'react';
import { GlobalState } from '../../../GlobalState';
import SlideShow from '../../slideShow/SlideShow';
import SlideShow2 from '../../slideShow2/SlideShow2';
import ProductItem from '../Utils/productItem/ProductItem';
import Publicidad from '../Utils/publicidad/Publicidad';
import Loading from '../Utils/loading/Loading';
import axios from 'axios';
import Filters from './Filters';
import { Link } from "react-router-dom";
import BackToTopButton from '../Utils/BackToTopButton/BackToTopButton';
import PromoRegresiva from '../Utils/promoregresiva/PromoRegresiva';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import dummyAvatar from './img/logotiendavirtual.jpg';


import { FaAngleDoubleRight } from 'react-icons/fa';
import { FaAngleDoubleLeft } from 'react-icons/fa';
import { FaWhatsapp } from "react-icons/fa";

// import Notificacion from '../Utils/alert/Notificacion';

function Products() {
   
    const state = useContext(GlobalState);
    const [products, setProducts] = state.productsAPI.products;
    const [isAdmin] = state.userApi.isAdmin;
    const [isStore] = state.userApi.isStore;
    const [token] = state.token;
    const [callback, setCallback] = state.productsAPI.callback;
    const [loading, setLoading] = useState(false);
    const [isCheck,setIsCheck] = useState(false);
    const [page, setPage] = state.productsAPI.page;
    const [user] = state.userApi.user;
    // const [superpromo, setSuperPromo] = state.productsAPI.superpromo;

    const [postPerPage] = useState(15);

    const lastPost = page * postPerPage;
    const firstPost = lastPost - postPerPage;
    const currentPost = products.slice(firstPost, lastPost);
    const pageNumber = [];

    for (let i = 1; i <= Math.ceil(products.length / postPerPage); i++) {
      pageNumber.push(i);
    }

    // const ChangePage = (pageNumber) => {
    //   setPage(pageNumber);
    // };

    const handleCheck = (id) => {
        products.forEach(product => {
          if(product._id === id) product.checked = !product.checked
        });
        setProducts([...products]);
      }

      const deleteProduct = async(id, public_id) => {
        try {
          setLoading(true)
          const destroyImg = await axios.post('/api/destroy', {public_id}, {
            headers: {Authorization: token}
          })
    
          const deleteProduct = await axios.delete(`api/products/${id}`, {
            headers: {Authorization: token}
          })
    
          await destroyImg
          await deleteProduct
          setCallback(!callback)
          setLoading(false);
        } catch (err) {
          alert(err.response.data.msg)
        }
      }

      const checkAll = () => {
        products.forEach(product => {
          product.checked = !isCheck
        });
        setProducts([...products]);
        setIsCheck(!isCheck);
      }
      const deleteAll = () => {
        products.forEach(product => {
          if(product.checked) deleteProduct(product._id, product.images.public_id)
        });
      }
      const color_fondo = {
        background: "white",
        color: 'gainsboro',
        cursor: 'default',
      }

    if(loading) return <div><Loading/></div>

    return (  
      <div>      
        <div className='textoinicial1'>
          <p>Entérate</p>
          <SlideShow slider={1}/>
        </div>
        <div className='textoinicial'>
          <p>Categorías</p>
          <SlideShow2/> 
        </div>     
        <>
        <div className="filtrofijo">
          <Filters/>
        </div>
        {
          isAdmin &&
          <div className='delete-all'>
            <span>Seleccionar Todos</span>
            <input type="checkbox" checked={isCheck} onChange={checkAll} />
            <button onClick={deleteAll}>Eliminar Todos</button>
          </div>
        }
        <div className='products'>            
            {
              isStore ? 
                currentPost.map(product => {
                  if(product.store === user.name)
                    return <ProductItem key={product._id} product={product} 
                    isAdmin={isAdmin} deleteProduct={deleteProduct} handleCheck={handleCheck}/>     
                      })
              :
              currentPost.map(product => {
              return <ProductItem key={product._id} product={product} 
              isAdmin={isAdmin} deleteProduct={deleteProduct} handleCheck={handleCheck}/>     
                })
            } 
        </div>
        {/* <LoadMore/> */}
        {products.length === 0 && <Loading />}
        <div className='load_more'>
          {page === 1 ? <FaAngleDoubleLeft className='next' style={color_fondo}  disabled={true}>
            anterior             
          </FaAngleDoubleLeft>
          :
          <FaAngleDoubleLeft className='next' onClick={() => setPage(page - 1)}>
                anterior             
          </FaAngleDoubleLeft>}
                
          {page * postPerPage >= products.length ? <FaAngleDoubleRight className='next' 
            style={color_fondo} disabled={true}>
            Siguiente             
          </FaAngleDoubleRight>
          :<FaAngleDoubleRight className='next'  onClick={() => setPage(page + 1)}>
                Siguiente             
            </FaAngleDoubleRight>}
        </div>
        </>
        <div className="textoinicial">
            <p>Espacio disponible para su empresa o negocio, contáctanos:<a href="https://wa.me/3174870105"> <FaWhatsapp >
                          
                          </FaWhatsapp> 3174870105</a></p>
            
            
            <Publicidad tipo={3} stilo={0}/>        
        </div>
        <PromoRegresiva/>
        <div className='promo'>
          <Link id='btn_view' to={`/ofertas`}>
          <Publicidad tipo={6} stilo={0}/>
          </Link>
        </div>
        <div className="publi1">
          <Publicidad tipo={9} stilo={2}/>
        </div>
        <div className="div">
          <BackToTopButton top={380}/>
        </div> 
        <div>
        <FloatingWhatsApp  phoneNumber="+57 3008669624"
          accountName="Comprandoenbello"
          chatMessage="Como puedo ayudarte"
          statusMessage="Envíanos tu mensaje para atenderte"
          placeholder="Mensaje..."
          avatar={dummyAvatar} 
          position="left"
          allowEsc
          allowClickAway
          notification
          notificationSound />
        </div>
    </div>    

    )
};

export default Products
