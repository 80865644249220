import { useEffect, useState, Fragment, useContext } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./forgotpassword.css";
import NotFound from "../Utils/not_found/NotFound";
import { GlobalState } from '../../../GlobalState';


const PasswordReset = () => {
    const state = useContext(GlobalState);
	const [validUrl, setValidUrl] = useState(false);
	const [password, setPassword] = useState('');
	const [otp2, setOTP2] = useState('');
	const [msg, setMsg] = useState('');
	const [error, setError] = useState('');
	const param = useParams();
    const [otp, setOTP] = useState('');
	const [disable, setDisable] = useState(true);
	const [timerCount, setTimer] = useState(60);

	useEffect(() => {
		const verifyUrl = async () => {
			try {
                const Mensaje = await axios.post('/user/passwordReset',{id:param.id}, {
                    headers: {Authorization: param.token}
                })
				setValidUrl(true);
				setOTP(param.otp);
			} catch (error) {     
                if(error.response.status === 400){
                    setValidUrl(false)
                    alert(error.response.data.msg)
                }               
			}
		};
		verifyUrl();
	}, [param.id,param.token]);

	useEffect(() => {
		let interval = setInterval(() => {
		  setTimer(lastTimerCount => {
			lastTimerCount <= 1 && clearInterval(interval);
			if (lastTimerCount <= 1){
				setDisable(false);
				setOTP('');
			} else {
				return lastTimerCount - 1;
			}
		  });
		}, 1000);
		return () => clearInterval(interval);
	}, [disable]);


	const resendOTP = async () => {
		if (disable) return;
		const OTP = Math.floor(Math.random() * 9000 + 1000); 
	   	await axios.post('/user/reset_password',{email: param.email,otp:OTP})
		  .then(() => setDisable(true))
		  .then(() => setOTP(OTP))
		  .then(() => alert("Enviamos un nuevo código a su correo para restablecer la contraseña."))
		  .then(() => setTimer(60))
		  .catch(console.log);	
	  }

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			console.log(otp + ' - ' + otp2)
			if(parseInt(otp) === parseInt(otp2)){
				const res = await axios.put(`/user/actualizarpassword/${param.id}`, {password}, {
					headers: {Authorization: param.token}
				})
				setError("");
				alert(res.data.msg)
				window.location = "/login";
			}else {
				alert('El código de verificación no es correcto.');
			}
		} catch (error) {
			if (
				error.response &&
				error.response.status >= 400 &&
				error.response.status <= 500
			) {
				setError(error.response.data.message);
				setMsg("");
			}
		}
	};

	return (
		<Fragment>
			{validUrl ? (
				<div className='container'>
					<form className='login-page' onSubmit={handleSubmit}>
						<h1>Restablecer Contraseña</h1>
						<h4>Ingrece el código enviado a su correo</h4>
						<input
							type="password"
							placeholder="Nueva contraseña"
							name="password"
							onChange={(e) => setPassword(e.target.value)}
							value={password}
							required
							className='input'
						/>
						<input 
							type="text" 
							placeholder='Código de verificación'
							name='otp2'  
							onChange={(e) => setOTP2(e.target.value)}
							value={otp2} 
							className='input'
							required
						/>						
							<div className="otp">
								{
								disable ? <p>Tiempo para ingresar el código <span>{timerCount}</span> s</p>
								: <h4 onClick={() => resendOTP()}>Solicitar código</h4>
								}
							</div>
						

						{/* <div className="otp">
						<a onClick={() => resendOTP()} > 
							{disable ? `Tiempo para ingresar el código  ${timerCount}s` : "Solicitar código"}
						</a>
						</div> */}

						{error && <div className='error_msg'>{error}</div>}
						{msg && <div className='success_msg'>{msg}</div>}
						<button type="submit" className='green_btn'>
							Enviar
						</button>
					</form>
				</div>
			) : (
				<NotFound />
			)}
		</Fragment>
	);
};

export default PasswordReset;