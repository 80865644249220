import {useState,useEffect} from 'react';
import axios from 'axios';

function PromocionesAPI() {
    const [promociones, setPromociones] = useState([]);
    const [pagepromo, setPagepromo] = useState(1);
    const [category, setCategory] = useState('');
    const [callback, setCallback] = useState(false)


    useEffect(() => {
      const getProducts = async () => {
          // const res = await axios.get('/api/promociones');
          const res = await axios.get(`/api/promociones?&${category}`);
          setPromociones(res.data);
      }
      getProducts();
  },[callback,pagepromo,category])


  return {
    promociones: [promociones, setPromociones],
    callback: [callback, setCallback],
    pagepromo: [pagepromo, setPagepromo],
    category: [category, setCategory]
  }
};

export default PromocionesAPI;