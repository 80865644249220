import React, {useContext, useState } from "react";
import axios from "axios";
// import { Link } from "react-router-dom";
import { GlobalState } from '../../../GlobalState';

import './forgotpassword.css'

const ForgotPassword = () => {
	const state = useContext(GlobalState);
	const [otp, setOTP] = state.otp;
	const [token] = state.token;
	const [email, setEmail] = useState("");
	const [msg, setMsg] = useState("");
	const [error, setError] = useState("");

	const modificarOTP = (otp) => {
		setOTP(otp);
	}

	const handleSubmit = async e => {
		e.preventDefault();
		try {
			const OTP = Math.floor(Math.random() * 9000 + 1000); 
			  modificarOTP(OTP);
			const Mensaje = await axios.post('/user/reset_password',{email: email,otp:OTP});
			alert(Mensaje.data.msg)

			window.location.href = `/PasswordReset/${Mensaje.data.user}/${Mensaje.data.token}/${OTP}/${email}`;

		} catch (err) {
			alert(err.response.data.msg)
		}
	};

	return (
		<div className='container'>
			<form className='login-page' onSubmit={handleSubmit}>
				<h2>Restablecer Contraseña</h2>
				<input
					type="email"
					placeholder="Correo"
					name="email"
					onChange={(e) => setEmail(e.target.value)}
					value={email}
					required
					className='input'
				/>
				{error && <div className='error_msg'>{error}</div>}
				{msg && <div className='success_msg'>{msg}</div>}
				<button type="submit" className='green_btn'>
					Enviar
				</button>
			</form>
		</div>
	);
};

export default ForgotPassword;