import axios from 'axios';
import React, { useEffect } from 'react'
import { useState,useefe } from 'react';

const DirectorioAPI = () => {
    const [callback, setCallback] = useState(false)
    const [directorio, setDirectorio] = useState([]);
    const [category, setCategory] = useState('');
    
    

    useEffect(() => {
        // setCategory('Vehiculos y motos')
        const getDirectorio = async () => {
            // const res = await axios.get('/api/directorio');

            const res = await axios.get(`/api/directorio?&${category}`);
            setDirectorio(res.data);
        }
        getDirectorio();
    },[callback,category]) 

  return {
    directorio: [directorio, setDirectorio],
    callback: [callback, setCallback],
    category: [category, setCategory]
  }
}

export default DirectorioAPI