import React from 'react';
import './home.css';
import { Link } from 'react-router-dom';
import Logotienda from './images/logotiendavirtual.jpg'
import image from './images/HP Gaming Pavilion.jpg'
import participa from './images/participa.jpg'
import { FaStaylinked } from 'react-icons/fa';
import { FaGlobe } from 'react-icons/fa';
import { FaPeopleArrows } from 'react-icons/fa';

const Home = () => {
  return (
    <div className='home'>
        <div className="secciones"></div>
        <section className='seccion1'>
            <img src={Logotienda}  alt=''/>
            <h1 className='titulohome'>Nuestra tienda online</h1>
            {/* <img src={image} alt='' /> */}
            <p className='textohome'>Encuentra todo lo que necesitas para tus necesidades.</p>
            {/* <Link className='btn_view_promo' to='#!' >
                Ver
            </Link> */}
        </section> 
        <section className="about">
            <h2 className="subtitle">¿Qué encontraras en nuestra tienda online?</h2>
            <p className="about__paragraph">Todo lo necesario para toda ocación, Calzado, Vestuario,   Ropa infantíl, Lencería, Bisutería, Tecnología, Marroniquería, Agencias de viajes</p>
            <h2 className="subtitle">Contamos con</h2>
            <div className="about__main">
                <div className="about__icons">
                    <FaStaylinked/>
                    <h3 className="about__title">Servicios Profesionales</h3>
                    <p className="about__paragrah">Empresas de Salud y Belleza. 
                    </p>
                </div>

                <div className="about__icons">
                    <FaGlobe/>                  
                    <h3 className="about__title">Comercio</h3>
                    <p className="about__paragrah">Calzado, Vestuario, Bisutería
                    Perfumería, Tecnología, Viajes, Ropa interior, Lencería, Hogar.
                    </p>
                </div>

                <div className="about__icons">
                    <FaPeopleArrows/>
                    <h3 className="about__title">Productos en remate</h3>
                    <p className="about__paragrah">Encuentra en nuestra tienda online comprandoenbello.com, un espacio donde encontraras los mejores productos con descuentos espectaculares.</p>
                </div>
            </div>
        </section>
        <section className="knowledge">
            <div className="knowledge__container">
                <div className="knowledege__texts">
                    <h2 className="subtitle">Participa y gana</h2>
                    <p className="knowledge__paragraph">Ingresa tus datos y responde las preguntas para obtener un descuento especial en tu compra, una tarjeta regalo o un premio sorpresa.</p>
                    {/* <a href="#" className="cta">Participar</a> */}
                    <Link className='cta' to={`/Premios`}>
                        Participar
                    </Link>
                </div>
                <figure className="knowledge__picture">
                    <img src={participa}className="knowledge__img"/>
                </figure>
            </div>
        </section>
        <div className="subtitle3"><h3>Portafolio de clientes</h3></div>
        <section className="testimony">
            <div className="testimony__container container">
                <img src="./images/leftarrow.svg" className="testimony__arrow" id="before"/>

                <section className="testimony__body testimony__body--show" data-id="1">
                    <div className="testimony__texts">
                        <h2 className="subtitle2">Mi nombre es Jordan Alexander, <span className="testimony__course">estudiante
                                de 6 semestre de Administración.</span></h2>
                        <p className="testimony__review">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ut est
                            esse, asperiores eaque totam nulla repudiandae quasi, deserunt culpa exercitationem
                            blanditiis laborum veniam laboriosam saepe reiciendis dolorem. Cum, ratione voluptatum!</p>
                    </div>

                    <figure className="testimony__picture">
                        <img src="./images/face.jpg" className="testimony__img"/>
                    </figure>
                </section>

                <section className="testimony__body" data-id="2">
                    <div className="testimony__texts">
                        <h2 className="subtitle">Mi nombre es Alejandra Perez, <span className="testimony__course">estudiante de
                                CSS.</span></h2>
                        <p className="testimony__review">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ut est
                            esse, asperiores eaque laborum veniam laboriosam saepe reiciendis dolorem. Cum, ratione
                            voluptatum!</p>
                    </div>

                    <figure className="testimony__picture">
                        <img src="./images/face2.jpg" className="testimony__img"/>
                    </figure>
                </section>
                <img src="./images/rightarrow.svg" className="testimony__arrow" id="next"/>
            </div>
        </section>
    </div>
  )
}

export default Home