import axios from 'axios';
import React, { useEffect } from 'react'
import { useState,useefe } from 'react';



const ParametrosAPI = () => {
    // const [pagepromo, setPagepromo] = useState(1);
    const [callback, setCallback] = useState(false)
    const [parametros, setParametros] = useState([]);

    useEffect(() => {
        const getParametros = async () => {
            const res = await axios.get('/api/parametros');
            setParametros(res.data);
        }
        getParametros();
    },[callback])   


  return {
    parametros: [parametros, setParametros],
    callback: [callback, setCallback]
  }
}

export default ParametrosAPI