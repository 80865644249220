import React from 'react'
import './promoitem.css'
import BtnRender from '../productItem/BtnRender';

const PromoItem = ({product,isAdmin, deleteProduct, handleCheck}) => {
  
  return (
    <div className='promo_cart'>  
              {
                isAdmin && <input type='checkbox' checked={product.checked}
                onChange={() => handleCheck(product._id)} />
              }
              {/* {
                product.promotion === true ? <h3>Promoción</h3>: null
              } */}
            <img src={product.images.url} alt='' />
            <div className='promo_box'>
            <h2 title={product.title}>{product.title}</h2>
            <h1 store={product.store}>{product.store}</h1>
            <div className="precio">
              <span>${new Intl.NumberFormat().format(product.price2)}</span>
              
              <span className="preci2"><span className="antes">Antes-</span>${new Intl.NumberFormat().format(product.price)}</span>
            </div>
            
            <p>{product.description}</p>
        </div>        

        <BtnRender product={product} deleteProduct={deleteProduct}/>   
    </div>
  )
}

export default PromoItem