import React from 'react'
import './politicas.css'

export const PoliticaGarantia = () => {
  return (
    <div className='politica'>
        <p className='titulo'>Política de garantía</p>
        <p className='texto'>En comprandoenbello.com pensamos en nuestros clientes, es por esto qué nos interesa que conozcan sobre la política y generalidades en el proceso de garantía. A continuación, detallamos lo que debes tener en cuenta:</p>
        <p className='titulo2'>¿Cuándo puedes solicitar la Garantía Legal?</p>
        <p className='texto'>Durante el término de garantía legal, el producto presente fallas relacionadas con su funcionamiento, defectos y estos no hayan sido ocasionados por uso indebido del producto, intervención de un tercero no autorizado.</p>
        <p className='titulo2'>Política</p>
        <p className='texto'>Cuando el cliente adquiere un producto, recibirá información sobre las instrucciones para su respectivo uso, mantenimiento y utilización de los productos de acuerdo con la naturaleza de estos y su respectivo tiempo de garantía.</p>
        <p className='texto'>El proveedor tiene derecho a reponer o reparar el producto o bien recibido, siempre y cuando esté admita reparación, en caso de que, el proveedor establezca que el producto no admite reparación este otorgará el cambio, reposición del producto o reembolsar el dinero del producto pagado al momento de la compra.</p>
        <p className='texto'>Todo trámite se gestionará en un tiempo máximo de treinta (30) días hábiles siguientes a la recepción del producto, dado que la primera instancia es la reparación del producto y éste se enviará al proveedor para su diagnóstico técnico y su reparación (si aplica). Si requieren el diagnóstico, éste se emitirá dentro de los quince (15) días hábiles contados a partir del día siguiente a la recepción de la solicitud. (Decreto 735 del 2013).</p>
        <p className='texto'>*La garantía no es procedente cuando se evidencia una mala manipulación del artículo por parte del cliente, o cuando ha superado el tiempo límite de reclamación ofrecido por el fabricante / vendedor.</p>
        <p className='titulo2'>Según el artículo 16 de la Ley 1480, su producto no tendrá garantía, si el diagnóstico emitido es:</p>
        <ul>
            <li>Hubo fuerza mayor o caso fortuito.</li>
            <li>El hecho de un tercero.</li>
            <li>El uso indebido del bien por parte de cliente.</li>
            <li>Que el consumidor no haya atendido las instrucciones de uso o mantenimiento indicados en el manual del producto y en la garantía.</li>
        </ul>
        <p className='texto'>Para hacer uso de la garantía o cambio de producto, el cliente podrá contactar a comprandoenbello.com quien proporcionará los datos del vendedor o proveedor para que éste le brinde un soporte adecuado a tu solicitud de garantía o para una respuesta más ágil, debe comunicarse directamente con el vendedor o aliado comercial que generó tu factura dentro de los tiempos estipulados para la garantía de su producto, en caso de no obtener respuesta debe comunicarse al correo de servicio al cliente comprandoenbello@gmail.com, allí le ayudaremos a gestionar su solicitud lo antes posible.</p>
        <p className='texto'>En caso de repetirse la falla en el artículo comprado, a elección del consumidor se procederá a una nueva reparación, la devolución del precio pagado o a la reposición del producto por otro de la misma especie, similares características o especificaciones técnicas (Art. 11, Ley 1480).</p>
        <p className='texto'>En el caso de optarse por la reposición del producto objeto de garantía legal, el despacho de esta reposición deberá realizarse a más tardar dentro de los diez (10) días hábiles siguientes al momento a la disposición del vendedor.</p>
        <p className='texto'>Cuando se trate de la garantía de un servicio, se procederá a la prestación del servicio en las condiciones en que fue contratado o a la devolución del precio pagado.</p>
        <p className='titulo2'>En caso en que se deba hacer la devolución de dinero, este se realizará de la siguiente manera:</p>
        <p className='texto'>A partir del día siguiente de la aceptación de la garantía por parte del proveedor y su posterior comunicación con comprandoenbello.com y el cliente, el proveedor tiene hasta 30 días para efectuar el reembolso. La devolución o desembolso del dinero, corresponderá al pago por el mismo al momento de realizar la compra, el tiempo de desembolso regirá en el momento que los asesores de comprandoenbello.com cuenten con toda la información requerida y esta se realizará al medio de pago usado en la compra.</p>
    </div>
  )
}
