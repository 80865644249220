import React, {useContext} from 'react';
import {GlobalState} from "../../../GlobalState";


const Filters = () => {
    const state = useContext(GlobalState);
    const [categories] = state.categoriesAPI.categories;
    const [category, setCategory] = state.productsAPI.category;
    const [sort, setSort] = state.productsAPI.sort;
    const [search, setSearch] = state.productsAPI.search;
    const [page, setPage] = state.productsAPI.page;


const handleCategory = e => {
    setCategory(e.target.value);
    setPage(1);
    setSearch('');
}
const handleSorting = e => {
    setCategory('');
    setSearch(e.target.value.toLowerCase());
    setPage(1);
}
  return (
    <div className="filter_menu">
       <div className="row  aaa">
            <span>Buscar por Categoría : </span>
            <select name="category" value={category} onChange={handleCategory}>
                <option value="">Todos Los Productos</option>
                {
                    categories.map(category => (
                        <option value={'category=' + category._id} key={category._id}>
                            {category.name}
                        </option>
                    ))
                }
            </select>
       </div> 
       <input type="text" value={search} placeholder='¿Que estás buscando?' 
       onChange={handleSorting}/>
       {/* onChange={e => setSearch(e.target.value.toLowerCase())}/> */}

        <div className="row sort">
            <span>Buscar Por : </span>
            <select value={sort} onChange={e => setSort(e.target.value)}>
                <option value="">Lo más nuevo</option>
                <option value="sort=oldest">Lo más viejo</option>
                <option value="sort=-sold">Lo más vendido</option>
                <option value="sort=-price">Precio altos a bajos</option>
                <option value="sort=price">Precios bajos a altos</option>
            </select>
       </div> 
    </div>
  )
}

export default Filters