import React, {useState, useEffect} from 'react';
import axios from 'axios';

const StoreAPI = () => {
    const [store, setStore] = useState([]);

    const getStores = async () => {
        // const res = await axios.get('/api/slider');
        // setSliders(res.data);

        const res = await axios.get('/api/store');
        setStore(res.data);
    }

    useEffect(() => {
        getStores();
    },[])

  return {
    stories: [store, setStore]
  }
}

export default StoreAPI