import React, {useState,useEffect,useContext} from 'react'
// import {useParams} from 'react-router-dom';
import {GlobalState} from '../../../GlobalState';


const Directorio = () => {
    const state = useContext(GlobalState);
    const [directorio] = state.directorioAPI.directorio;
    const [callback, setCallback] = state.directorioAPI.callback;
    const [directorios, setDirectorios] = useState([]);
    const [category, setCategory] = state.directorioAPI.category;

    // const params = useParams();

    useEffect(() => {
        // if(params.id) {
        //     directorio.forEach(item => {
        //         if(item._id === params.id) setDirectorios(item)
        //     })
        // }
        setCategory('Vehiculos y motos')
    },[]);
    

    if(directorio.length === 0) return null;

  return (
    <div className='directorio'>
        <table style={{margin: '30px 0px'}}>
            <thead>
                <tr>
                    <th>Categoría</th>
                    <th>Empresa o Negocio</th>
                    <th>Teléfono</th>
                    <th>Ubicación</th>    
                </tr>
            </thead>
            <tbody>
                {
                    directorio.map(item => (
                        <tr key={item._id}>
                            {
                                <> 
                                <td>{item.categoria}</td>
                                <td>{item.empresa}</td>
                                <td>{item.telefono}</td>
                                <td>{item.ubicacion}</td>
                                </>
                            }                           
                        </tr>
                    ))
                }
            </tbody>
        </table>
    </div>
  )
}

export default Directorio
