import React, {useContext} from "react";
import { Link } from "react-router-dom";
import {GlobalState} from '../../../../GlobalState';

// import Notificacion from "../alert/Notificacion";
// import Swal from "sweetalert2";


function BtnRender({product, deleteProduct}) {
  const state = useContext(GlobalState);
  const [isAdmin] = state.userApi.isAdmin;
  const addCart = state.userApi.addCart;
  const [isStore] = state.userApi.isStore;

  return (
    
    <div>
      {
        <div className='row_btn'>
          {
            isAdmin || isStore ?
            <>
              <Link id='btn_buy' to='#!' 
              onClick={() => deleteProduct(product._id, product.images.public_id)}>
                Eliinar
              </Link>
              <Link id='btn_view' to={`/edit_product/${product._id}`}>                
                Editar               
              </Link>
            </> :
            <>
              <Link id='btn_buy' to='#!' onClick={() => addCart(product)} >
              {/* <Link id='btn_buy' to='#!' onClick={() => llamar()} > */}
                Comprar
              </Link>
              
              <Link id='btn_view' to={`/detail/${product._id}`}>
                Ver
              </Link>
            </>
          }        
        </div> 
      }
   </div>
  )
}

export default BtnRender;