// import React from 'react'
import React, {useContext} from 'react';
import {GlobalState} from "../../../../GlobalState";
import {Link} from 'react-router-dom';
import '../slideItem2/slideItem2.css';

const SlideItem2 = ({category1}) => {
    const state = useContext(GlobalState);

    const [category, setCategory] = state.productsAPI.category;
    const [search, setSearch] = state.productsAPI.search;
    const [page, setPage] = state.productsAPI.page;

    const handleCategory = e => {
        setCategory('category=' + e);
        setPage(1);
        setSearch('');
    }

  return (
        <div className='slide2'>
            <img src={category1.images.url} alt='' 
                onClick={() => handleCategory(category1._id)}/>
                {/* <div className='row_btn2'>
                    <button onClick={() => handleCategory(category1._id)}>
                        Ver todos</button>
                </div> */}
        </div>
  )
}

export default SlideItem2