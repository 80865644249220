import React from 'react';
import { Link } from 'react-router-dom';
import Logometrobelloshop from './img/comprandoenbello2.png';
import Logotienda from './img/logotiendavirtual.jpg';
import logodsm from './img/LogoDSM3.png';
import intagramlogo from './img/instagram.png';
import gmaillogo from './img/gmail.png';
import './footer.css';
import { FaRegCopyright } from 'react-icons/fa';


const Footer = () => {
  return (
    <div className='pie-pagina'>
        <div className="grupo-1">
            <div className="boxfooter">
                <Link to='/' className='logo'>
                    <img src={Logometrobelloshop} alt='' width='300' />
                </Link>
                <div className='dsm'>
                        <img src={Logotienda} alt='' width='200'/>
                </div>
                {/* <div className='logo'>                 
                    <a href="https://metrobello315.wixsite.com/ccmetrobello"><img src={Logotienda} alt='' width='200' /></a>
                </div>     */}
                {/* <div>
                    <p>Visita nuestra página</p>
                </div>             */}
            </div>
            <div className="boxfooter">
                <h2>Sobre Nosotros</h2>
                <p>Somos la tienda virtual más completa del Municipio de Bello, contamos con almacenes de todo tipo de artículos y servicios profesionales</p>
                <Link to='/reembolso' className='logo'>
                    <p>Política de reembolso</p>
                </Link>
                <Link to='/garantia' className='logo'>
                    <p>Política de garantias</p>
                </Link>
                <Link to='/envio' className='logo'>
                    <p>Política de envíos</p>
                </Link>  
                <Link to='/comocomprar' className='logo'>
                    <p>Cómo comprar en comprandoenbello.com</p>
                </Link>             
            </div>
            <div className="boxfooter">
                <h2>Síguenos</h2>
                <div className="red-social">
                <div className='red'>
                    <img src={intagramlogo} alt='' /> 
                    <p>comprandoenbello</p>
                </div>
                <div className='red'>
                    <img src={gmaillogo} alt='' /> 
                    <p>comprandoenbello@gmail.com</p>
                </div>
                    <div className='dsm'>
                        <img src={logodsm} alt='' width='200'/>
                    </div>
                    <div>
                        <p>Visita nuestra página</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="grupo-2">
            <small><FaRegCopyright /> 2023 <b>COMPRANDOENBELLO.COM</b> - Todos los derechos reservados.</small>
        </div>
    </div>
  )
}

export default Footer